<template>
  <!-- 一番上にメッセージ表示。4秒で自動で消える -->
  <div style="margin-top: 10px; color: red">
    {{ message }}
  </div>
  
  <!-- 未入室の場合、部屋を作る or 部屋に入るを選択 -->
  <RoomEntry
    v-if="!isJoined"
    :socket="socket"
    @createRoom="createRoom"
    @enterRoom="enterRoom"
  />

  <!-- 再入室確認モーダル -->
  <RoomReEntryModal
    v-if="!isJoined"
    :socket="socket"
  />

  <!-- 部屋の中のレイアウト -->
  <div v-if="isJoined">
    <!-- ゲームが始まったら -->
    <div v-if="inGame && isReady">
      <div v-if="inResult === false">
        <!-- ゲームの処理 -->
        <form @submit.prevent="postAnswer">
          <v-row
            justify="center"
            align="center"
            no-gutters
            style="height: 100%;"
          >
            <v-col
              class="d-flex align-center"
              cols="auto"
              style="flex: 1; max-width: 500px; margin-right: 10px;"
            >
              <v-text-field
                label="カードの名前を入力"
                v-model="answer"
                type="input"
                variant="outlined"
                maxlength="150"
                style="width: 100%; height: 45px;"
              ></v-text-field>
            </v-col>
            <v-col
              class="d-flex align-center"
              cols="auto"
              style="flex: 0; margin-right: 10px;"
            >
              <v-btn
                elevation="8"
                size="x-large"
                type="submit"
                :disabled="!answer"
                style="height: 56px;"
              >
                回答
              </v-btn>
            </v-col>
            <v-col
              class="d-flex align-center"
              cols="auto"
              style="flex: 0;"
            >
              <v-btn
                elevation="8"
                size="x-large"
                type="button"
                color="#ff0000"
                style="height: 56px;"
                @click="passedAnswer"
              >
                {{ isPassed ? "キャンセル" : "パス" }}
              </v-btn>
            </v-col>
          </v-row>
        </form>

        <!-- リザルトモーダル -->
        <ResultModal
          :socket="socket"
          :parsedEffect1="Array.isArray(parsedEffect1) ? parsedEffect1 : []"
          :parsedEffect2="Array.isArray(parsedEffect2) ? parsedEffect2 : []"
        />

        <v-container class="cardEffect">
          <v-row>
            <!-- parsedEffect1 と parsedEffect2 を並べて表示 -->
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12">
                  <template v-for="(part, effectIndex) in parsedEffect1" :key="effectIndex">
                    <template v-if="!part.isInput">
                      <span>{{ part.text }}</span>
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        v-model="inputValues1[effectIndex]"
                        :placeholder="part.text"
                        @input="(event) => changeForecast(1, effectIndex, event)"
                        :maxlength="part.text.length"
                        :style="{ 
                          width: part.text.length * 1.8 + 'ch', 
                          border: 'none', 
                          outline: 'none', 
                          color: part.color || 'black' // 色を動的に設定
                        }"
                      />
                    </template>
                  </template>
                </v-col>
                <v-col cols="12">
                  <!-- 仕切り線。第二効果が存在する場合のみ出現 -->
                  <v-divider v-if="parsedEffect2 && parsedEffect2.length > 0" :thickness="4" class="border-opacity-50" color="info"></v-divider>
                </v-col>
                <v-col cols="12" md="8">
                  <template v-for="(part, effectIndex) in parsedEffect2" :key="effectIndex">
                    <template v-if="!part.isInput">
                      <span>{{ part.text }}</span>
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        v-model="inputValues2[effectIndex]"
                        :placeholder="part.text"
                        @input="(event) => changeForecast(2, effectIndex, event)"
                        :maxlength="part.text.length"
                        :style="{ 
                          width: part.text.length * 1.8 + 'ch', 
                          border: 'none', 
                          outline: 'none', 
                          color: part.color || 'black' // 色を動的に設定
                        }"
                      />
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-col>

            <!-- 広告スペース -->
            <v-col cols="12" md="4" class="advertisement-container">
              <div class="advertisement">
                <!-- 広告コンテンツをここに追加 -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- リザルトの処理 -->
      <GameResult
        v-if="inResult"
        :socket="socket"
        :isNext="isNext"
        :answerUser="answerUser"
        :currentCard="currentCard"
        :resultCardEffect1="resultCardEffect1"
        :resultCardEffect2="resultCardEffect2"
        @nextQuestion="nextQuestion"
        @resultTweet="resultTweet"
        @recruitTweet="recruitTweet"
      />
    </div>

    <!-- ゲームしてない時 -->
    <SelectColor
      v-if="!inGame || !isReady"
      :socket="socket"
      :isReady="isReady"
      :userColor="userColor"
      :selectedColors="selectedColors"
      @readyToggle="readyToggle"
      @setUserColor="setUserColor"
      @recruitTweet="recruitTweet"
    />

    <!-- 退室ボタン -->
    <v-col cols="12">
      <v-btn
        elevation="8"
        size="x-large"
        type="submit"
        style="height: 56px;"
        @click="openDisconnectModal"
      >
        退室
      </v-btn>
    </v-col>
            
    <!-- 退室モーダル -->
    <v-dialog v-model="disconnectModal" max-width="290">
      <v-card>
        <v-card-title>
          退室しますか？
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="roomDisconnect">はい</v-btn>
          <v-btn color="secondary" text @click="disconnectModal = false">いいえ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- チャットスペース+参加者一覧 -->
    <ChatSpace
      :socket="socket"
      :winStreak="winStreak"
      :users="users"
      :inGame="inGame"
      :passedUsers="passedUsers"
      :nextUsers="nextUsers"
      :readyUsers="readyUsers"
    />

  </div>
</template>
  
<script>
  import io from "socket.io-client";
  import { mapGetters, mapActions } from 'vuex';
  import { yugiohNgWords, duelMastersNgWords, shadowverseNgWords, normalNgWords, hellNgWords } from '../ngWords.js';
  import RoomEntry from './RoomEntry.vue';
  import RoomReEntryModal from './RoomReEntryModal.vue';
  import SelectColor from './SelectColor.vue';
  import ChatSpace from './ChatSpace.vue';
  import ResultModal from './ResultModal.vue';
  import GameResult from './GameResult.vue';

  export default {
    name: "MainContent",
    components: {
    RoomEntry,
    RoomReEntryModal,
    SelectColor,
    ChatSpace,
    ResultModal,
    GameResult,
  },
  data: () => ({
    isJoined: false, // 部屋に参加している状態かどうか
    users: [], // 参加者一覧
    userColor: null, // ユーザーカラー
    selectedColors: [], // 部屋内で選ばれたカラー
    message: "", // ヘッダーに表示する赤メッセージ
    answer: "", // 正解入力
    answerUser: null, // 回答者
    cardType: '', // カードジャンル
    currentCard: null, // 現在表示されているカード
    filteredEffects: [], // 黒塗りされたカード効果1+2
    filteredEffect1: "", // 黒塗りされたカード効果1
    filteredEffect2: "", // 黒塗りされたカード効果2
    parsedEffect1: [], // 黒塗りされたカード効果1を分解したもの
    parsedEffect2: [], // 黒塗りされたカード効果2を分解したもの
    inputValues1: [], // カード効果1予想
    inputValues2: [], // カード効果2予想
    readyUsers: [], // 準備完了状態のユーザーID
    passedUsers: [], // パス状態のユーザーID
    nextUsers: [], // 次の問題状態のユーザーID
    inGame: false, // ゲーム中かどうか
    inResult: false, // リザルト中かどうか
    isReady: false, // 準備完了状態
    isPassed: false, // パス待ち状態
    isNext: false, // 次の問題待ち状態
    disconnectModal: false, // 退室確認ダイアログの表示状態
    winStreak: 0, // 連続正解数
    difficulty: "normal", // 部屋の難易度
    resultCardEffect1: '',
    resultCardEffect2: '',
    forecasts: [],
    socket: io(`${process.env.VUE_APP_API_ROOT_URL}`), // こことソケット通信するで
  }),

  computed: {
    ...mapGetters(['roomId', 'userId', 'userName', 'isAuthenticated', 'userBadge']), // Vuex のゲッターをマッピング
  },

  created() {
    this.socket.on("connect", () => {
      console.log("connected");
    });
  },

  mounted() {
    this.socket.on("updateRoom", (room, card, users, readyUsers, passedUsers, nextUsers) => {
      this.isJoined = true;
      this.updateRoomId(room.room_id); // 部屋番号を保存
      this.cardType = room.card_type;
      this.winStreak = room.win_streak;
      this.currentCard = card;
      this.inGame = room.in_game;
      this.difficulty = room.difficulty;
      this.answerUser = room.answer_user;
      this.inResult = room.in_result;
      this.users = users;
      this.readyUsers = readyUsers;
      this.passedUsers = passedUsers;
      this.nextUsers = nextUsers;
    });

    // カード効果表示
    this.socket.on('updateCard', (card,cardType) => {

      // カードが取得できないと終了
      if (card === null){
        console.log("カード取得できてなくて草");
        return;
      }

      this.forecasts = [];
      this.currentCard = card;
      const filteredEffects = this.getFilteredEffect(card.card_effect1,card.card_effect2,card.card_name,cardType);

      // filteredEffectsには、filteredEffectとfilteredEffect2が含まれている
      const filteredEffect1 = filteredEffects.filteredEffect1;
      const filteredEffect2 = filteredEffects.filteredEffect2;

      // filteredEffect1が存在する場合にのみparseEffectを適用
      if (filteredEffect1) {
        this.parsedEffect1 = this.parseEffect(filteredEffect1); // parseEffect関数を呼び出してparsedEffectに格納
        this.inputValues1 = Array(this.parsedEffect1.length).fill(''); // inputValues を初期化
      } else {
        this.parsedEffect1 = []; // filteredEffect2がない場合、parsedEffect2を空に設定
        this.inputValues1 = []; // inputValues2を空の配列に設定
      }

      // filteredEffect2が存在する場合にのみparseEffectを適用
      if (filteredEffect2) {
        this.parsedEffect2 = this.parseEffect(filteredEffect2);
        this.inputValues2 = Array(this.parsedEffect2.length).fill('');
      } else {
        this.parsedEffect2 = []; // filteredEffect2がない場合、parsedEffect2を空に設定
        this.inputValues2 = []; // inputValues2を空の配列に設定
      }
    });

    this.socket.on("notifyError", (error) => {
      console.log("エラーメッセージ受信:", error);
      this.message = error;
    });

    // 誰かが入った時に表示すること
    this.socket.on("notifyConnection", (userName) => {
      this.message = userName + " さんが入室しました";
    });

    // 誰かが抜けた時に表示すること
    this.socket.on("notifyDisconnection", (userName) => {
      this.message = userName + " さんが退室しました";
    });

    // キックされた時に表示すること
    this.socket.on("notifyKicked", () => {
      this.message = "退室しました";
      this.updateRoomId(null); // 部屋番号を削除
      window.location.reload();
    });

    // サーバーから選択済みの色のリストを受け取る
    this.socket.on('updateSelectedColors', (colors) => {
      this.selectedColors = colors;
    });

    // カード効果予測。リアルタイム反映
    this.socket.on('updateForecasts', (forecasts) => {
      this.forecasts = forecasts;
      if (Array.isArray(forecasts)) {
        forecasts.forEach(([effectNumber, effectIndex, value, color]) => { 
          if (effectNumber === 1) {
            this.inputValues1[effectIndex] = value;
            this.parsedEffect1[effectIndex].color = color;
          } else if (effectNumber === 2) {
            this.inputValues2[effectIndex] = value;
            this.parsedEffect2[effectIndex].color = color;
          }
        });
      } else {
        console.error('予測データが無効です:', forecasts);
      }
    });

    // 予測データを返す
    this.socket.on('getForecasts', () => {
      this.socket.emit("changeForecasts", this.forecasts);
    });

    // 予測データの成語判定
    this.socket.on('judgeForecasts', () => {
      this.socket.emit("judge", this.forecasts, this.parsedEffect1 || '', this.parsedEffect2 || '');
    });

    this.socket.on("showResult", (resultCardEffect1, resultCardEffect2) => {
      this.resultCardEffect1 = resultCardEffect1 || '';
      this.resultCardEffect2 = resultCardEffect2 || '';
    });

    // 回答クリア
    this.socket.on("clearAnswer", () => {
      this.answer = "";
    });

    // パスフラグクリア
    this.socket.on("clearPassed", () => {
      this.isPassed = false;
    });

    // 次の問題フラグクリア
    this.socket.on("clearNext", () => {
      this.isNext = false;
    });

    // 再入場時にvueのユーザー状態を更新
    this.socket.on("updateUserStatus", (data) => {
      this.isReady = data.isReady;
      this.isPassed = data.isPassed;
      this.isNext = data.isNext;
      this.userColor = data.color;
    });

    // ユーザーの情報を更新
    this.socket.on("updateUserVuex", (userId, userName) => {
      this.updateUserId(userId);
      this.updateUserName(userName);
    });
  },

  watch: {
    message(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.message = '';
        }, 4000); // 4秒後にメッセージをクリア
      }
    },
  },

  methods: {
    ...mapActions(['updateRoomId', 'updateUserId', 'updateUserName']),
    
    createRoom(data) {
      // 基本のデータオブジェクトを作成
      const payload = {
        userName: data.userName,
        isAuthenticated: this.isAuthenticated,
        userBadge: this.userBadge,
        cardType: data.cardType,
        yugiohRange: data.yugiohRange,
        duelMastersRange: data.duelMastersRange,
        yugiohFamousCards: data.yugiohFamousCards,
        duelMastersFamousCards: data.duelMastersFamousCards,
        shadowverseFamousCards: data.shadowverseFamousCards,
        difficulty: data.difficulty,
      };

      // isAuthenticatedがtrueの時だけuserIdを追加
      if (data.isAuthenticated) {
        payload.userId = this.userId;
      }

      // ソケット通信を送信
      this.socket.emit("create", payload);
    },

    enterRoom(inputName, roomId, isAuthenticated, userBadge) {
      // 基本のデータオブジェクトを作成
      const payload = {
        inputName: inputName,
        isAuthenticated: isAuthenticated,
        userBadge: userBadge,
        roomId: roomId,
      };

      // isAuthenticatedがtrueの時だけuserIdを追加
      if (isAuthenticated) {
        payload.userId = this.userId;
      }
      this.socket.emit("enter", payload);
    },

    readyToggle() {
      this.isReady = !this.isReady;
      this.socket.emit("ready", this.isReady);
    },

    setUserColor(color) {
      if (!this.selectedColors.includes(color)) {
        this.userColor = color;
        this.socket.emit('setColor', this.userColor);
      }
    },

    parseEffect(effect) {
      if (!effect) {
        console.error('カード効果ないよ:', effect);
        return []; // 返り値として空の配列を返す
      }

      // ■の連続部分と通常テキスト部分を分割
      const inputRegex = /(■+)/g;
      const parts = effect.split(inputRegex).map((part) => {
        return {
          text: part,
          isInput: inputRegex.test(part) // ■の部分かどうかを判定
        };
      });

      return parts;
    },

    postAnswer() {
      this.socket.emit("answer", this.answer);
      // 部屋の全員にリザルトモーダルを表示できるようにサーバーを仲介
      this.socket.emit("resultModal", this.answer);
      this.answer = "";
    },

    passedAnswer() {
      this.isPassed = !this.isPassed;
      this.socket.emit("passed", this.isPassed);
    },

    openDisconnectModal() {
      this.disconnectModal = true;
    },

    roomDisconnect() {
      this.socket.emit("roomDisconnect");
      this.disconnectModal = false;
    },

    nextQuestion() {
      this.isNext = !this.isNext;
      this.socket.emit("next", this.isNext);
    },

    // 予測した文字を反映
    changeForecast(effectNumber, effectIndex, event) {
      // 同じ effectNumber と effectIndex のデータを探す
      const existingIndex = this.forecasts.findIndex(forecast =>
        forecast[0] === effectNumber && forecast[1] === effectIndex
      );

      if (existingIndex !== -1) {
        // 既存のデータが見つかった場合、その値を更新する
        this.forecasts[existingIndex][2] = event.target.value;
        this.forecasts[existingIndex][3] = this.userColor;
      } else {
        // 見つからなければ、新しいデータを追加する
        this.forecasts.push([effectNumber, effectIndex, event.target.value, this.userColor]);
      }
      this.socket.emit("changeForecasts", this.forecasts);
    },

    // 正規表現の特殊文字をエスケープするメソッド
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

    // サーバーから受け取ったカードの効果をフィルタリングするメソッド
    getFilteredEffect(effect1 = [], effect2 = [], cardName, cardType) {
      // effect1 と effect2 が配列でない場合は空の配列を設定する
      Array.isArray(effect1) ? effect1 : [];
      Array.isArray(effect2) ? effect2 : [];
      
      // 共通処理を行うヘルパー関数を定義
      const filterEffect = (effect) => {
        // 遊戯王カードの共通処理
        if (cardType == "yugioh") {
          // まず、「」の中の文字列を■に変換（ただし記号はそのまま）
          const quoteRegex = /「(.*?)」/g;
          effect = effect.replace(quoteRegex, (match, p1) => {
            // 記号はそのまま残して、それ以外を■に変換
            return '「' + p1.replace(/[^\s・～~＝=：:☆★’'！!？?－−\-−＞＜><,.／/“”"「」【】『』卍♪＃#♡♥、。＄$]/g, '■') + '」';
          });
        }

        // デュエマカードの共通処理
        if (cardType == "duelMasters") {
          // まず、《》の中の文字列を■に変換（ただし記号はそのまま）
          const quoteRegex = /《(.*?)》/g;
          effect = effect.replace(quoteRegex, (match, p1) => {
            // 記号はそのまま残して、それ以外を■に変換
            return '《' + p1.replace(/[^\s・～~＝=：:☆★’'！!？?－−\-−＞＜><,.／/“”"「」【】『』卍♪＃#♡♥、。＄$]/g, '■') + '》';
          });
        }

        // シャドバカードの共通処理
        if (cardType == "shadowverse"){
          // まず、カード名と被る部分を■に変換
          // cardNameを「・」で分割
          const parts = cardName.split('・');
          // 各部分を■で置き換える
          parts.forEach(part => {
            const partRegex = new RegExp(part, 'g');
            effect = effect.replace(partRegex, '■'.repeat(part.length));
          });
        }

        // ノーマルモード処理
        if (this.difficulty === "normal"){
          // 一部文字を■に変換
          normalNgWords.forEach(word => {
            const regex = new RegExp(this.escapeRegExp(word), 'g');
            effect = effect.replace(regex, '■'.repeat(word.length));
          });
        }

        // ヘルモード処理
        if (this.difficulty === "hell"){
          // 動詞を■に変換
          hellNgWords.forEach(word => {
            const regex = new RegExp(this.escapeRegExp(word), 'g');
            effect = effect.replace(regex, '■'.repeat(word.length));
          });
        }

        // ハード・ヘルモード共通処理
        if (this.difficulty === 'hard' || this.difficulty === 'hell'){
          // 遊戯王カードの処理
          if (cardType == "yugioh"){
            // 遊戯王テキストを■に変換
            yugiohNgWords.forEach(word => {
              const regex = new RegExp(this.escapeRegExp(word), 'g');
              effect = effect.replace(regex, '■'.repeat(word.length));
            });
          }

          // デュエマカードの処理
          if (cardType == "duelMasters"){
            // デュエマテキストを■に変換
            duelMastersNgWords.forEach(word => {
              const regex = new RegExp(this.escapeRegExp(word), 'g');
              effect = effect.replace(regex, '■'.repeat(word.length));
            });
          }

          // シャドバカードの処理
          if (cardType == "shadowverse"){
            // シャドバテキストを■に変換
            shadowverseNgWords.forEach(word => {
              const regex = new RegExp(this.escapeRegExp(word), 'g');
              effect = effect.replace(regex, '■'.repeat(word.length));
            });
          }

          // カタカナを全て■に変換（ただし、記号は除くが、伸ばし棒は変換する）
          effect = effect.replace(/[\u30A1-\u30FA\u30FD\u30FE\u30FC]/g, '■');
        }

        return effect;
      };

      // cardEffect1が存在する場合、フィルタリング
      if (effect1) {
        effect1 = filterEffect(effect1);
      }

      // cardEffect2が存在する場合、フィルタリング
      if (effect2) {
        effect2 = filterEffect(effect2);
      }

      return { filteredEffect1: effect1, filteredEffect2: effect2 };
    },

    // 募集用ツイート
    recruitTweet() {
      // カード範囲を確認
      let shareType = "";
      let shareRoomId = this.roomId;

      // 選択されているcardTypeに応じてshareTypeとshareRangeを格納
      if (this.cardType === "yugioh") {
        shareType = "遊戯王";
      } else if (this.cardType === "duelMasters") {
        shareType = "デュエマ";
      } else if (this.cardType === "shadowverse") {
        shareType = "シャドバ";
      }

      //シェアする画面を設定
      var shareURL = 'https://twitter.com/intent/tweet?text=' + "穴埋めカードクイズ（ジャンル：" + shareType +
                      ")で一緒に遊びませんか？部屋番号：" + shareRoomId +
                      '%20%23' + "穴埋めカードクイズ " + "%20%0A" +
                      '&url=' + encodeURIComponent("https://fillingcard.3zukie.jp/?shareRoomId=" + shareRoomId); 
      //シェア用の画面を別ウィンドウで開く
      window.open(shareURL, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    // リザルト用ツイート
    resultTweet(correct_name = null) {
      // カード範囲を確認
      let shareType = "";
      let winTweet = "";

      // 選択されているcardTypeに応じてshareTypeとshareRangeを格納
      if (this.cardType === "yugioh") {
        shareType = "遊戯王";
      } else if (this.cardType === "duelMasters") {
        shareType = "デュエマ";
      } else if (this.cardType === "shadowverse") {
        shareType = "シャドバ";
      }

      // 勝利していた場合、ツイート文を変化
      if (this.winStreak >= 2 && correct_name.length <= 65){
        winTweet = "で、「" + correct_name + "」を正解し、" + this.winStreak + "問連続正解しました！"
      } else if (this.winStreak == 1 && correct_name.length <= 65){
        winTweet = "で、「" + correct_name + "」を正解しました！"
      } else {
        winTweet = "で遊んだよ！"
      }

      //シェアする画面を設定
      var shareURL = 'https://twitter.com/intent/tweet?text=' + "穴埋めカードクイズ（ジャンル：" + shareType +
                      ")" + winTweet + "みんなも遊んでみよう！%0A" +
                      '%20%23' + "穴埋めカードクイズ " + "%20%0A" +
                      '&url=' + "https://fillingcard.3zukie.jp/?Twitter";  
      //シェア用の画面を別ウィンドウで開く
      window.open(shareURL, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },
  },
};
</script>
  
<style>
  /* スマホサイズのスタイル調整 */
  @media (max-width: 600px) {
    .v-text-field, .v-btn {
      font-size: 0.8rem;
      padding: 0.5rem;
    }
    .v-col {
      margin-bottom: 10px;
    }
    .v-btn {
      margin-bottom: 10px;
    }
  }

  /* カード効果のスタイル */
  .cardEffect {
    max-width: 800px; /* 折り返しを行う最大幅を指定 */
    margin: 0 auto; /* 中央揃えのための左右の自動マージン */
    white-space: pre-wrap; /* 改行コードを適用 */
  }

  .cardEffect span, 
  .cardEffect input {
    /* 入力フィールドの枠線を消す */
    border: none;
    outline: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    margin: 2px 0; /* 上下のマージンを少し追加 */
  }

  .advertisement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .advertisement {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
</style>