<template>
  <v-dialog v-model="resultModalFlag" max-width="600px" persistent>
    <v-card>
      <v-card-title>正誤判定</v-card-title>
      <v-card-text class="cardEffect">
        <v-row justify="center">
          <v-col cols="auto">
            {{ resultModalText }}
          </v-col>
          <v-col cols="auto">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ResultModal',
  props: {
    socket: { type: Object, required: true },
    parsedEffect1: { type: Array, required: true },
    parsedEffect2: { type: Array, required: true },
  },
  data() {
    return {
      resultModalFlag: false, // リザルトモーダルの表示状態
      resultModalText: "", // リザルトモーダル内のテキスト
      resultType: null, // 正誤タイプ。0:不正解・1:正解・2:おしい
    };
  },

  mounted() {
    this.handleAnswerResult = (resultType) => {
      this.resultType = resultType;
    };

    this.handleShowResultModal = (answer) => {
      this.resultModalFlag = true;
      this.resultModalText = "回答：" + answer + "\n答え合わせ中...";

      setTimeout(() => {
        if (this.resultType === 1) {
          this.resultModalText = "正解！";
        } else if (this.resultType === 2) {
          this.resultModalText = "おしい！";
        } else if (this.resultType === 0) {
          this.resultModalText = "不正解！";
        } else {
          this.resultModalText = "answerメソッド内のエラー";
        }
      }, 2000);

      setTimeout(() => {
        this.resultModalFlag = false;
        this.socket.emit("result", {
          resultType: this.resultType,
          parsedEffect1: this.parsedEffect1,
          parsedEffect2: this.parsedEffect2
        });
      }, 4000);
    };

    this.socket.on("answerResult", this.handleAnswerResult);
    this.socket.on("showResultModal", this.handleShowResultModal);
  },

  unmounted() {
    this.socket.off("answerResult", this.handleAnswerResult);
    this.socket.off("showResultModal", this.handleShowResultModal);
  }
};
</script>