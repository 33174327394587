import { createRouter, createWebHistory } from 'vue-router'
import MainContent from './components/MainContent.vue'
import InfoPage from './components/InfoPage.vue'
import RankingPage from './components/RankingPage.vue'
import AccountLogin from './components/AccountLogin.vue'
import AccountRegister from './components/AccountRegister.vue'
import AccountHome from './components/AccountHome.vue'

const routes = [
  { path: '/', component: MainContent },
  { path: '/InfoPage', component: InfoPage },
  { path: '/RankingPage', component: RankingPage },
  { path: '/AccountLogin', component: AccountLogin },
  { path: '/AccountRegister', component: AccountRegister },
  { path: '/AccountHome', component: AccountHome },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router