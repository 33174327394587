<template>
  <v-container>
    <!-- タブ部分 -->
    <v-tabs
      v-model="tab"
      fixed-tabs
      color="deep-purple-accent-4"
      style="margin-bottom: 20px"
    >
      <v-tab value="info">自分の情報</v-tab>
      <v-tab value="setting">プロフィール変更</v-tab>
      <v-tab value="achievement">実績</v-tab>
    </v-tabs>

    <!-- 各タブの内容 -->
    <v-window v-model="tab">
      <!-- 自分の情報タブ -->
      <v-window-item value="info">
        <v-card class="mx-auto my-5 text-center" max-width="800" elevation="3">
          <v-card-title class="deep-purple white--text d-flex justify-center align-center">
            <v-icon left size="36">mdi-account</v-icon>
            <span style="font-size: 24px;">自分の情報</span>
          </v-card-title>
          <v-card-text>
            <v-row class="justify-center">
              <!-- ユーザー名 -->
              <v-col cols="12" class="text-center">
                <div class="d-flex justify-center align-center">
                  <v-icon color="green" size="32">mdi-account-circle</v-icon>
                  <span class="ml-3" style="font-size: 18px;">ユーザー名: {{ user.user_name }}</span>
                </div>
              </v-col>

              <!-- ユーザーID -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="blue" size="32">mdi-account</v-icon>
                  <span class="ml-3" style="font-size: 18px;">ユーザーID: {{ user.auto_increment_number }}</span>
                </div>
              </v-col>

              <!-- ノーマル正解数 -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="orange" size="32">mdi-cube-outline</v-icon>
                  <span class="ml-3" style="font-size: 18px;">難易度ノーマル正解数: {{ user.correct_answers_normal }}</span>
                </div>
              </v-col>

              <!-- ハード正解数 -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="red" size="32">mdi-cube-scan</v-icon>
                  <span class="ml-3" style="font-size: 18px;">難易度ハード正解数: {{ user.correct_answers_hard }}</span>
                </div>
              </v-col>

              <!-- ヘル正解数 -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="purple" size="32">mdi-fire</v-icon>
                  <span class="ml-3" style="font-size: 18px;">難易度ヘル正解数: {{ user.correct_answers_hell }}</span>
                </div>
              </v-col>

              <!-- 最大連続正解数 -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="teal" size="32">mdi-trophy</v-icon>
                  <span class="ml-3" style="font-size: 18px;">最大連続正解数: {{ user.max_consecutive_answers }}</span>
                </div>
              </v-col>

              <!-- 今週の正解数 -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="pink" size="32">mdi-calendar-week</v-icon>
                  <span class="ml-3" style="font-size: 18px;">今週の正解数: {{ user.weekly_correct_answers }}</span>
                </div>
              </v-col>

              <!-- 設定バッジ -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="amber" size="32">mdi-badge-account-outline</v-icon>
                  <span class="ml-3" style="font-size: 18px;">設定バッジ: {{ user.setting_badge }}</span>
                </div>
              </v-col>

              <!-- アカウント作成日 -->
              <v-col cols="12" sm="6" class="text-center">
                <div class="d-flex align-center">
                  <v-icon color="indigo" size="32">mdi-calendar</v-icon>
                  <span class="ml-3" style="font-size: 18px;">アカウント作成日: {{ formattedDate }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-window-item>

      <!-- プロフィール変更タブ -->
      <v-window-item value="setting">
        <div>
          <v-card>
            <v-card-title>プロフィール変更</v-card-title>
            <v-card-text>
              <v-text-field
                label="ユーザー名"
                v-model="user.user_name"
                :rules="[v => !!v || 'ユーザー名は必須です', v => v.length <= 8 || '8文字以内で入力してください']"
              ></v-text-field>

              <v-select
                label="バッジ選択"
                :items="filteredBadges"
                v-model="user.setting_badge"
              ></v-select>

              <v-btn color="primary" @click="updateProfile">更新</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-window-item>

      <!-- 実績タブ -->
      <v-window-item value="achievement">
        <div>
          <v-card class="large-card">
            <v-card-title>実績</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-text>

                        <!-- セクション1 -->
                        <template v-if="unlockedAchievementsList1.length">
                          <v-card class="middle-card"> <!-- 中カード -->
                            <h2>赤バッジ</h2>
                            <v-img :src="require('../assets/red_badge.jpg')" alt="achievement-icon" class="achievement-icon"></v-img> <!-- アイコンを中カードのタイトル下に表示 -->
                            <v-row>
                              <v-col v-for="(achievement, index) in unlockedAchievementsList1" :key="index" cols="auto" xs="6" sm="4" md="3">
                                <v-card class="small-card"> <!-- 小カード -->
                                  <v-card-title class="small-card-text">{{ achievement.title }}</v-card-title>
                                  <v-card-subtitle class="small-card-text">{{ achievement.description }}</v-card-subtitle>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </template>

                        <!-- セクション2 -->
                        <template v-if="unlockedAchievementsList2.length">
                          <v-card class="middle-card"> <!-- 中カード -->
                            <h2>金バッジ</h2>
                            <v-img :src="require('../assets/gold_badge.jpg')" alt="achievement-icon" class="achievement-icon"></v-img> <!-- アイコンを中カードのタイトル下に表示 -->
                            <v-row>
                              <v-col v-for="(achievement, index) in unlockedAchievementsList2" :key="index" cols="auto" xs="6" sm="4" md="4">
                                <v-card class="small-card"> <!-- 小カード -->
                                  <v-card-title class="small-card-text">{{ achievement.title }}</v-card-title>
                                  <v-card-subtitle class="small-card-text">{{ achievement.description }}</v-card-subtitle>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </template>

                        <!-- セクション3 -->
                        <template v-if="unlockedAchievementsList3.length">
                          <v-card class="middle-card"> <!-- 中カード -->
                            <h2>銀バッジ</h2>
                            <v-img :src="require('../assets/silver_badge.jpg')" alt="achievement-icon" class="achievement-icon"></v-img> <!-- アイコンを中カードのタイトル下に表示 -->
                            <v-row>
                              <v-col v-for="(achievement, index) in unlockedAchievementsList3" :key="index" cols="auto" xs="6" sm="4" md="4">
                                <v-card class="small-card"> <!-- 小カード -->
                                  <v-card-title class="small-card-text">{{ achievement.title }}</v-card-title>
                                  <v-card-subtitle class="small-card-text">{{ achievement.description }}</v-card-subtitle>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </template>

                        <!-- セクション4 -->
                        <template v-if="unlockedAchievementsList4.length">
                          <v-card class="middle-card"> <!-- 中カード -->
                            <h2>銅バッジ</h2>
                            <v-img :src="require('../assets/bronze_badge.jpg')" alt="achievement-icon" class="achievement-icon"></v-img> <!-- アイコンを中カードのタイトル下に表示 -->
                            <v-row>
                              <v-col v-for="(achievement, index) in unlockedAchievementsList4" :key="index" cols="auto" xs="6" sm="4" md="4">
                                <v-card class="small-card"> <!-- 小カード -->
                                  <v-card-title class="small-card-text">{{ achievement.title }}</v-card-title>
                                  <v-card-subtitle class="small-card-text">{{ achievement.description }}</v-card-subtitle>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </template>

                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      tab: 'info', // 初期タブ
      user: {
        user_name: '',
        correct_answers_normal: 0,
        correct_answers_hard: 0,
        correct_answers_hell: 0,
        max_consecutive_answers: 0,
        weekly_correct_answers: 0,
        setting_badge: 'なし',
        createdAt: ''
      },
      achievements: [],

      // 赤実績
      achievementsList1: [
        {
          title: '感謝バッジ',
          description: '特別な勲章',
          unlocked: false, // computedで設定
        },
        {
          title: '穴埋めクイズマスター',
          description: 'hellを100回正解',
          unlocked: false, // computedで設定
        },
      ],

      // 金実績
      achievementsList2: [
        {
          title: '廃人',
          description: 'hellを50回正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'ガチプレイヤー',
          description: 'hardを100回正解',
          unlocked: false, // computedで設定
        },
        {
          title: '百発百中',
          description: '連続正解数が20',
          unlocked: false, // computedで設定
        },
        {
          title: 'キスキル好き',
          description: 'キスキルを正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'シリウス!! 2体!',
          description: '天門かシリウスを2回正解',
          unlocked: false, // computedで設定
        },
        {
          title: '我こそは軍神',
          description: 'グリームニルを4回正解',
          unlocked: false, // computedで設定
        },
      ],

      // 銀実績
      achievementsList3: [
        {
          title: '物知り',
          description: 'hardのを50回正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'カードゲーマー',
          description: 'normalを100回正解',
          unlocked: false, // computedで設定
        },
        {
          title: '驚異の正答率',
          description: '連続正解数が10',
          unlocked: false, // computedで設定
        },
        {
          title: 'モンスターカード！',
          description: '狂戦士の魂を正解',
          unlocked: false, // computedで設定
        },
        {
          title: '神に選ばれし者',
          description: '三幻神を正解',
          unlocked: false, // computedで設定
        },
        {
          title: '破壊の化身',
          description: 'バジュラを2回正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'とんでもねぇデッキ',
          description: 'ホーガン・ブラスターを正解',
          unlocked: false, // computedで設定
        },
        {
          title: '処理落ち',
          description: '聖獅子カードを5回正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'うどんなのよ',
          description: 'ガルミーユを正解',
          unlocked: false, // computedで設定
        },
      ],

      // 銅実績
      achievementsList4: [
        {
          title: '次のステップへ',
          description: '問題を50回正解',
          unlocked: false, // computedで設定
        },
        {
          title: '片鱗',
          description: '連続正解数が5',
          unlocked: false, // computedで設定
        },
        {
          title: 'M-1出場',
          description: 'フレイムウィングマンを正解',
          unlocked: false, // computedで設定
        },
        {
          title: '意味不明のカード',
          description: 'エクゾディアを正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'レベル0？',
          description: '覇勝星イダテンを正解',
          unlocked: false, // computedで設定
        },
        {
          title: '左右BA',
          description: 'コントローラーを正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'とりあえずこいつだ',
          description: 'アクア・マスターを正解',
          unlocked: false, // computedで設定
        },
        {
          title: '別ゲー',
          description: 'スーパーしりとりガーを正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'カワイイは作れる',
          description: 'ちょっと☆きゃわ☆じょらごんを正解',
          unlocked: false, // computedで設定
        },
        {
          title: '風圧完全無効',
          description: '鋼龍 クシャルダオラを正解',
          unlocked: false, // computedで設定
        },
        {
          title: '間違いはありません',
          description: '背徳の狂獣を正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'さぁ、飛ぶわよ！',
          description: '次元の超越を正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'ぬんぬぬん',
          description: 'ユニコを正解',
          unlocked: false, // computedで設定
        },
        {
          title: 'メンコ好き',
          description: 'バハムートを正解',
          unlocked: false, // computedで設定
        },
      ],
    };
  },

  computed: {
    unlockedAchievementsList1() {
      return this.achievementsList1.filter(achievement => achievement.unlocked);
    },
    unlockedAchievementsList2() {
      return this.achievementsList2.filter(achievement => achievement.unlocked);
    },
    unlockedAchievementsList3() {
      return this.achievementsList3.filter(achievement => achievement.unlocked);
    },
    unlockedAchievementsList4() {
      return this.achievementsList4.filter(achievement => achievement.unlocked);
    },

    formattedDate() {
      const date = new Date(this.user.createdAt);
      return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString();
    },

    filteredBadges() {
      const available = ['なし']; // なしは常に選択可能

      if (this.unlockedAchievementsList4.length > 0) {
        available.push('銅'); // 銅を追加
      }
      if (this.unlockedAchievementsList3.length > 0) {
        available.push('銀'); // 銀を追加
      }
      if (this.unlockedAchievementsList2.length > 0) {
        available.push('金'); // 金を追加
      }
      if (this.unlockedAchievementsList1.length > 0) {
        available.push('赤'); // 赤を追加
      }

      return available;
    },
  },

  methods: {
    ...mapActions(['updateUserName', 'updateUserBadge']),

    updateProfile() {
      axios.put(`${process.env.VUE_APP_API_ROOT_URL}/api/users/${this.user.user_id}`, {
        user_name: this.user.user_name,
        setting_badge: this.user.setting_badge // 数字として保存
      })
      .then(() => {
        alert('プロフィールが更新されました');
      })
      .catch(error => {
        console.error('エラーが発生しました:', error);
      });

      // Vuexにユーザー情報を保存
      this.updateUserName(this.user.user_name);
      this.updateUserBadge(this.user.setting_badge);
    },

    // 実績1: 感謝バッジ
    hasAchievement1() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'fakeCardWithRed'
      );
    },

    // 実績2: 穴埋めクイズマスター
    hasAchievement2() {
      return this.user.correct_answers_hell >= 100;
    },

    // 実績3: 廃人
    hasAchievement3() {
      return this.user.correct_answers_hell >= 50;
    },

    // 実績4: ガチプレイヤー
    hasAchievement4() {
      return this.user.correct_answers_hard >= 100;
    },

    // 実績5: 百発百中
    hasAchievement5() {
      return this.user.max_consecutive_answers >= 20;
    },

    // 実績6: キスキル好き
    hasAchievement6() {
      const Cards = ['Evil★Twin キスキル', 'Live☆Twin キスキル', 'Live☆Twin キスキル・フロスト'];
      return this.achievements.some(
        (achievement) => Cards.includes(achievement.card_name)
      );
    },

    // 実績7: 天海の精霊シリウス!!!!! 2体!!
    hasAchievement7() {
      const Cards = ['ヘブンズ・ゲート', '天海の精霊シリウス / W・チャージャー'];
      // correct_answersの合計を計算
      const totalCorrectAnswers = this.achievements
        .filter(achievement => Cards.includes(achievement.card_name))
        .reduce((sum, achievement) => sum + achievement.correct_answers, 0);
      
      // 合計が2以上であればtrueを返す
      return totalCorrectAnswers >= 2;
    },

    // 実績8: 我こそは軍神、踊り狂う暴風
    hasAchievement8() {
      const Cards = ['風の軍神・グリームニル', '颶風の天槍・グリームニル', '颶風の軍神・グリームニル', '虚無ノ哭風・グリームニル'];
      // correct_answersの合計を計算
      const totalCorrectAnswers = this.achievements
        .filter(achievement => Cards.includes(achievement.card_name))
        .reduce((sum, achievement) => sum + achievement.correct_answers, 0);
      
      // 合計が4以上であればtrueを返す
      return totalCorrectAnswers >= 4;
    },

    // 実績9: 物知り
    hasAchievement9() {
      return this.user.correct_answers_hard >= 50;
    },

    // 実績10: カードゲーマー
    hasAchievement10() {
      return this.user.correct_answers_normal >= 100;
    },

    // 実績11: 驚異の正答率
    hasAchievement11() {
      return this.user.max_consecutive_answers >= 10;
    },

    // 実績12: ドロー！モンスターカード！
    hasAchievement12() {
      return this.achievements.some(
        (achievement) => achievement.card_name === '狂戦士の魂'
      );
    },

    // 実績13: 神に選ばれし者
    hasAchievement13() {
      const Cards = ['オベリスクの巨神兵', 'オシリスの天空竜', 'ラーの翼神竜'];
      return this.achievements.some(
        (achievement) => Cards.includes(achievement.card_name)
      );
    },

    // 実績14: 破壊の化身
    hasAchievement14() {
      const Cards = ['超竜バジュラ', '超竜バジュラズテラ', '超竜バジュラ・セカンド', 'バジュラ・ザ・スター', 'バジュラズ・ソウル'];
      // correct_answersの合計を計算
      const totalCorrectAnswers = this.achievements
        .filter(achievement => Cards.includes(achievement.card_name))
        .reduce((sum, achievement) => sum + achievement.correct_answers, 0);
      
      // 合計が2以上であればtrueを返す
      return totalCorrectAnswers >= 2;
    },

    // 実績15: とんでもねぇデッキ
    hasAchievement15() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'ホーガン・ブラスター'
      );
    },

    // 実績16: 処理落ち
    hasAchievement16() {
      const Cards = ['聖獅子の神殿', '聖獅子の結晶', '崇拝の実現'];
      // correct_answersの合計を計算
      const totalCorrectAnswers = this.achievements
        .filter(achievement => Cards.includes(achievement.card_name))
        .reduce((sum, achievement) => sum + achievement.correct_answers, 0);
      
      // 合計が5以上であればtrueを返す
      return totalCorrectAnswers >= 5;
    },

    // 実績17: うどんなのよ
    hasAchievement17() {
      const Cards = ['侮蔑の嘲笑', '侮蔑の絶傑・ガルミーユ'];
      return this.achievements.some(
        (achievement) => Cards.includes(achievement.card_name)
      );
    },

    // 実績18: 次のステップへ
    hasAchievement18() {
      return this.user.correct_answers_normal + this.user.correct_answers_hard + this.user.correct_answers_hell >= 50;
    },

    // 実績19: 片鱗
    hasAchievement19() {
      return this.user.max_consecutive_answers >= 5;
    },

    // 実績20: M-1出場
    hasAchievement20() {
      const Cards = ['E・HERO フレイム・ウィングマン','E・HERO フレイム・ウィングマン－フレイム・シュート'];
      return this.achievements.some(
        (achievement) => Cards.includes(achievement.card_name)
      );
    },

    // 実績21: 意味不明のカード
    hasAchievement21() {
      return this.achievements.some(
        (achievement) => achievement.card_name === '封印されしエクゾディア'
      );
    },

    // 実績22: レベルを持たないならレベル0
    hasAchievement22() {
      return this.achievements.some(
        (achievement) => achievement.card_name === '覇勝星イダテン'
      );
    },

    // 実績23: 左右BA
    hasAchievement23() {
      const Cards = ['エネミーコントローラー', 'ユニオン・コントローラー'];
      return this.achievements.some(
        (achievement) => Cards.includes(achievement.card_name)
      );
    },

    // 実績24: 弱いけどとりあえずこいつだ
    hasAchievement24() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'アクア・マスター'
      );
    },

    // 実績25: 別ゲー
    hasAchievement25() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'スーパーしりとりガー'
      );
    },

    // 実績26: カワイイは作れる
    hasAchievement26() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'ちょっと☆きゃわ☆じょらごん'
      );
    },

    // 実績27: 風圧完全無効
    hasAchievement27() {
      return this.achievements.some(
        (achievement) => achievement.card_name === '鋼龍 クシャルダオラ'
      );
    },

    // 実績28: 間違いはありません
    hasAchievement28() {
      return this.achievements.some(
        (achievement) => achievement.card_name === '背徳の狂獣'
      );
    },

    // 実績29: さぁ、飛ぶわよ！
    hasAchievement29() {
      return this.achievements.some(
        (achievement) => achievement.card_name === '次元の超越'
      );
    },

    // 実績30: ぬんぬぬん
    hasAchievement30() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'ユニコーンの踊り手・ユニコ'
      );
    },

    // 実績31: メンコ好き
    hasAchievement31() {
      return this.achievements.some(
        (achievement) => achievement.card_name === 'バハムート'
      );
    },
  },

  async created() {
    const isAuthenticated = await this.$store.dispatch('checkAuth');
    if (!isAuthenticated) {
      this.$router.push('/AccountLogin');
    }

    const userId = this.$store.state.userId;
    if (userId) {
      // ユーザー情報と実績データを一緒に取得
      await axios.get(`${process.env.VUE_APP_API_ROOT_URL}/api/users/${userId}`)
        .then(response => {
          const { user, achievements } = response.data;
          this.user = user;
          this.achievements = achievements;
          this.updateUserName(user.user_name);
          this.updateUserBadge(user.setting_badge);
        })
        .catch(error => {
          console.error('ユーザー情報の取得に失敗しました:', error);
        });
    }

    // 初期化時にも実績取得をチェック
    this.achievementsList1[0].unlocked = this.hasAchievement1();
    this.achievementsList1[1].unlocked = this.hasAchievement2();
    this.achievementsList2[0].unlocked = this.hasAchievement3();
    this.achievementsList2[1].unlocked = this.hasAchievement4();
    this.achievementsList2[2].unlocked = this.hasAchievement5();
    this.achievementsList2[3].unlocked = this.hasAchievement6();
    this.achievementsList2[4].unlocked = this.hasAchievement7();
    this.achievementsList2[5].unlocked = this.hasAchievement8();
    this.achievementsList3[0].unlocked = this.hasAchievement9();
    this.achievementsList3[1].unlocked = this.hasAchievement10();
    this.achievementsList3[2].unlocked = this.hasAchievement11();
    this.achievementsList3[3].unlocked = this.hasAchievement12();
    this.achievementsList3[4].unlocked = this.hasAchievement13();
    this.achievementsList3[5].unlocked = this.hasAchievement14();
    this.achievementsList3[6].unlocked = this.hasAchievement15();
    this.achievementsList3[7].unlocked = this.hasAchievement16();
    this.achievementsList3[8].unlocked = this.hasAchievement17();
    this.achievementsList4[0].unlocked = this.hasAchievement18();
    this.achievementsList4[1].unlocked = this.hasAchievement19();
    this.achievementsList4[2].unlocked = this.hasAchievement20();
    this.achievementsList4[3].unlocked = this.hasAchievement21();
    this.achievementsList4[4].unlocked = this.hasAchievement22();
    this.achievementsList4[5].unlocked = this.hasAchievement23();
    this.achievementsList4[6].unlocked = this.hasAchievement24();
    this.achievementsList4[7].unlocked = this.hasAchievement25();
    this.achievementsList4[8].unlocked = this.hasAchievement26();
    this.achievementsList4[9].unlocked = this.hasAchievement27();
    this.achievementsList4[10].unlocked = this.hasAchievement28();
    this.achievementsList4[11].unlocked = this.hasAchievement29();
    this.achievementsList4[12].unlocked = this.hasAchievement30();
    this.achievementsList4[13].unlocked = this.hasAchievement31();
  }
};
</script>

<style>
.large-text {
  font-size: 3vw; /* レスポンシブなフォントサイズ */
}

ul {
  padding-left: 1rem;
}

li {
  list-style-type: none; /* 点を消す */
  padding: 0.5rem 0;
}

/* 大カードのスタイル */
.large-card {
  margin: 20px; /* 外側の余白 */
}

/* 中カードのスタイル */
.middle-card {
  padding: 10px; /* 内側の余白 */
  margin: 10px 0; /* 上下の余白 */
  max-height: 300px; /* 中カードの最大高さを制限 */
  overflow-y: auto; /* コンテンツが溢れた場合にスクロール */
  text-align: center; /* タイトルとアイコンを中央に配置 */
}

/* 小カードのスタイル */
.small-card {
  margin: 2px; /* カード間のスペース */
  padding: 2px; /* カードの内側のスペース */
  border-radius: 8px; /* カードの角を丸くする */
  background-color: #dffbff; /* 薄い水色の背景色 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* カードの影 */
  width: 100%; /* 小カードの幅を100%にする */
  max-width: 220px; /* 小カードの最大幅 */
}

.small-card-text {
  font-size: 12px; /* デフォルトのフォントサイズを設定 */

  /* 画面幅が600px以上の小型デバイス向けのフォントサイズ */
  @media (min-width: 600px) {
    font-size: 14px;
  }

  /* 画面幅が960px以上の中型デバイス向けのフォントサイズ */
  @media (min-width: 960px) {
    font-size: 16px;
  }

  /* 画面幅が1264px以上の大型デバイス向けのフォントサイズ */
  @media (min-width: 1264px) {
    font-size: 18px;
  }
}

/* アイコンのスタイル */
.achievement-icon {
  width: 120px; /* アイコンの幅 */
  height: 120px; /* アイコンの高さ */
  margin: 0 auto; /* 中央に配置 */
}

</style>