<template>
  <v-app id="app">
    <!-- ヘッダー -->
    <v-app-bar
      color="deep-purple"
      absolute
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>穴埋めカードクイズ</v-toolbar-title>
      <!-- 入室済の場合、部屋の情報を表示 -->
      <div v-if="roomId || isAuthenticated">
        <v-row justify="center" style="margin-right: 30px;">
          <v-col v-if="isAuthenticated" cols="auto"> {{ userName }}さん</v-col>
          <v-col v-if="!isAuthenticated" cols="auto"> ゲストさん</v-col>
          <v-col v-if="roomId" cols="auto" @click="copyRoomIdToClipboard">部屋番号: {{ roomId }}</v-col>
        </v-row>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        dense
        nav
      >

        <v-list-item prepend-icon="mdi-information" title="Info" @click="navigateTo('/InfoPage#info')"></v-list-item>
        <v-list-item prepend-icon="mdi-gamepad-variant" title="Game" @click="navigateTo('/')"></v-list-item>
        <v-list-item prepend-icon="mdi-account" title="Account" @click="navigateTo('/AccountHome')"></v-list-item>
        <v-list-item prepend-icon="mdi-crown" title="Ranking" @click="navigateTo('/RankingPage')"></v-list-item>
        <v-list-item prepend-icon="mdi-twitter" title="Twitter" @click.stop.prevent="navTwitter"></v-list-item>
        <v-list-item prepend-icon="mdi-security" title="Policy" @click="navigateTo('/InfoPage#policy')"></v-list-item>
        <v-list-item prepend-icon="mdi-delete" title="Clear" @click="Clear"></v-list-item>
        <v-list-item prepend-icon="mdi-arrow-collapse" title="toziru" @click="drawer = false"></v-list-item>

      </v-list>
    </v-navigation-drawer>
    <v-main>

      <!-- ルートの情報が表示される -->
      <router-view></router-view>

    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "App",
  data: () => ({
    isJoined: false,
    drawer: false, // ナビゲーションドロワーの表示
  }),

  computed: {
    ...mapGetters(['roomId', 'userName', 'isAuthenticated']), // Vuex のゲッターをマッピング
  },

  methods: {
    ...mapActions(['updateRoomId', 'updateUserId', 'updateUserName', 'updateUserBadge', 'updateTokens']),

    // ページ遷移を行うメソッド
    navigateTo(path) {
      this.$router.push(path);
    },

    // Twitterページに遷移
    navTwitter() {
      const url = "https://x.com/3zukiE"
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    // デバッグ用。Vuexとローカルストレージの中身を削除
    Clear() {
      localStorage.clear(); // ローカルストレージの中身を削除
      this.updateRoomId(null); // 部屋番号を削除
      this.updateUserId(null); // ユーザーIDを削除
      this.updateUserName(""); // ユーザー名を削除
      this.updateUserBadge('なし'); // ユーザーバッジを削除
      this.updateTokens( { idToken: null, refreshToken: null } ); // トークンを削除
      this.drawer = false;
      window.location.reload(); // ページをリロード
    },

    copyRoomIdToClipboard() {
      navigator.clipboard.writeText(this.roomId)
        .then(() => {
          alert("部屋番号がクリップボードにコピーされました！");
        })
        .catch(err => {
          alert("部屋番号のコピーに失敗しましたｗ");
          console.error('Could not copy text: ', err);
        });
    }
  },
};
</script>

<style>
/* 全体のスタイル */
#app {
  text-align: center;
  font-family:"Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
}

/* v-list-item のホバー時のスタイル */
.v-list-item:hover {
  background-color: rgba(156, 39, 176, 0.1); /* ホバー時の背景色 */
}

.v-list-item:hover .v-list-item-title {
  color: #6200EA; /* text--accent-4 の色 */
}

/* v-list-item のアクティブ時のスタイル */
.v-list-item.active-item {
  background-color: rgba(156, 39, 176, 0.1); /* アクティブ時の背景色 */
}

.v-list-item.active-item .v-list-item-title {
  color: #6200EA; /* text--accent-4 の色 */
}
</style>