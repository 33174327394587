<template>
  <v-tabs
    v-model="tab"
    fixed-tabs
    color="deep-purple-accent-4"
    style="margin-bottom: 20px"
  >
    <v-tab value="info">穴埋めカードクイズとは？</v-tab>
    <v-tab value="hint">考え方のヒント</v-tab>
    <v-tab value="policy">プライバシーポリシー</v-tab>
    <v-tab value="inquiry">問い合わせ</v-tab>
    <v-tab value="omanke">おまけ</v-tab>
  </v-tabs>

  <v-tabs-window v-model="tab">
    <v-tabs-window-item value="info">
      <v-container>
        <v-row>
          <v-col>
            <v-card class="mx-auto my-4" max-width="800">
              <v-card-title class="headline">穴埋めカードクイズとは？</v-card-title>
              <v-card-text>
                <div class="content">
                  <p class="section">
                    <strong>概要</strong>
                  </p>
                  <p>
                    カード効果の一部（主に名詞）が隠れているので、効果を推測しカード名を当てるクイズゲームです。<br>
                    例）モンスター→■■■■■ 破壊する→■■する カード1枚をドローする→■■■■枚を■■■する。<br>
                    ※難易度調整のため、名詞でも一部、隠れていないものもあります。<br>
                    オンライン対応していますが、現時点では協力モードのみです。
                  </p>

                  <p class="section">
                    <strong>左上のメニュー説明</strong>
                  </p>
                  <p>
                    Info：このページです<br>
                    Game：ゲーム画面です<br>
                    Account：アカウント登録し、様々なデータを保存できます<br>
                    Ranking：先週の正解数ランキングです<br>
                    Twitter：フォローしてください<br>
                    Policy：このサイトのプライバシーポリシーです<br>
                    Clear：いろいろリセットするボタンです。バグった時とかに
                  </p>

                  <p class="section">
                    <strong>ゲームの始め方</strong>
                  </p>
                  <p>
                    ホストが部屋を作成し、部屋番号を他の人に伝えます。<br>
                    他の人は部屋番号を使って部屋に入室します。（部屋は最大6人まで入れます）<br>
                    部屋に入ったら好きな色を選んでから準備完了ボタンを押してください。<br>
                    参加者全員が準備完了するとゲームがスタートします。<br>
                    一部隠れたカード効果が表示されるので、カード名を推測してください。
                  </p>

                  <p class="section">
                    <strong>ゲームの遊び方</strong>
                  </p>
                  <p>
                    ■になっている効果の部分は選択すると文字を入力できます。<br>
                    推測した効果をどんどん入れていきましょう！推測部分にはどんな文字を入れてもミスにはなりません。<br>
                    入力文字は全プレイヤーの画面にリアルタイムで共有されます。知恵を出し合い効果を埋めていきましょう。<br>
                    カード名が分かったら、上の回答欄に入力し、回答ボタンを押せば回答できます！
                  </p>

                  <p class="section">
                    <strong>難易度について</strong>
                  </p>
                  <p>
                    難易度には「ノーマル」「ハード」「ヘル」の3つがあります。<br>
                    「ノーマル」：一部の文字のみ隠れます。慣れるまではこの難易度で遊びましょう！<br>
                    「ハード」：ほぼ全ての名詞が隠れます。カードゲーマー向けです。<br>
                    「ヘル」：ハードの文字に加えて、動詞まで隠れます。クリアを想定していません。<br>
                    難易度ノーマルでプレイしている場合、実績は取得できません。
                  </p>

                  <p class="section">
                    <strong>ゲーム画面について</strong>
                  </p>
                  <p>
                    画面上部にあるのが回答欄と回答ボタン、パスボタンです。<br>
                    回答欄にカード名を入力した後、回答ボタンで回答できます。<br>
                    ギブアップしたい場合はパスボタンを押します。全員がパスすると問題を飛ばせます。<br>
                    <br>
                  </p>
                  <p>
                    画面中央にあるのがカードの効果です。一部の文字が隠れています。<br>
                    もし効果が2つある場合、青い横線で区切られます。<br>
                    遊戯王の「ペンデュラム」、デュエマの「ツインパクト」、シャドバの「フォロワー」が該当します。<br>
                    また、デュエマの「超次元」などの両面カードは表面と裏面で別のカードとして扱います。<br>
                    <br>
                  </p>
                  <p>
                    画面下部はチャット欄と参加者一覧です。<br>
                    チャットはゲームの進行に依存しません。雑談やカード効果の相談などにお使いください。<br>
                    参加者一覧は現在部屋に参加しているプレイヤー名を表示しています。パス待ちや準備完了などの状態も表示されます。<br>
                    ホストプレイヤーは参加者をクリックするとキックすることが出来ます。
                  </p>

                  <p class="section">
                    <strong>回答の正誤判定について</strong>
                  </p>
                  <p>
                    回答の正誤判定は回答欄のみで行われます。効果予測や、チャット欄に何が書かれていても影響しません。<br>
                    回答は、「モンスター名」もしくは「モンスター名のルビ」に一致した場合のみ正解になります。<br>
                    <br>
                    例えば正解のカードが「閉ザサレシ世界ノ冥神」だった場合、<br>
                    ・「閉ザサレシ世界ノ冥神」→カード名と一致するので正解になります。<br>
                    ・「サロス＝エレス・クルヌギアス」→カードルビと一致するので正解になります。<br>
                    ・「サロスエレスクルヌギアス」→記号は短縮しても正解になります。<br>
                    ・「閉ざされし世界の冥神」→ひらがな・カタカナは区別していないので正解になります。<br>
                    ・「とざされしせかいのめいしん」→カードルビと異なった読み方なので不正解になります。<br>
                    ・「サロス」→短縮した名前やアダ名には対応していないので不正解になります。<br>
                    <br>
                    また、回答がニアミスの場合「おしい！」が出る場合があります。<br>
                    この場合、正解でも不正解でもなく、もう一度回答できます。連続正解数も変更しません。<br>
                    なお、レーベンシュタイン距離に基づいた類似度計算で80%以上一致していた場合「ニアミス」と判定します。
                  </p>

                  <p class="section">
                    <strong>アカウントについて</strong>
                  </p>
                  <p>
                    アカウントを作成すると、正解数などが保存できるようになります。<br>
                    また、一部の問題に正解するなどの一定の条件で実績を獲得できます。<br>
                    レアな金実績取得を目指して頑張ってください！<br>
                    アカウント作成時に入力する情報についての取り扱いはプライバシーポリシーをご確認ください。
                  </p>

                  <p class="section">
                    <strong>その他注意</strong>
                  </p>
                  <p>
                    データは作者（瑞稀）が手動で設定しています。<br>
                    そのため、一部のデータが間違っている可能性があります。<br>
                    また、効果が被っているカードは除外しています。<br>
                    例）<br>
                    コッコ・ルピアの「ドラゴンの召喚コストを２下げる」<br>
                    一番隊 クリスタは「メタリカの召喚コストを１下げる」<br>
                    ↑どちらも穴埋めだと「■■■■の■■■■■を■下げる」になり判別不可能<br>
                    これらの効果被りも手動で除外しているので、考慮漏れが存在する可能性があります。<br>
                    もし正解不可能の問題を発見したら連絡いただけると助かります！
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-tabs-window-item>

    <v-tabs-window-item value="hint">
      <v-container>
        <v-row>
          <v-col>
            <v-card class="mx-auto my-4" max-width="800">
              <v-card-title class="headline">考え方のヒント</v-card-title>
              <v-card-text>
                <div class="content">
                  <p class="section">
                    <strong>頻出単語を覚えておこう</strong>
                  </p>
                  <p>
                    よく出現する単語と、その文字数を把握しておきましょう<br>
                    カード/ドロー/ターン/デッキ/手札/墓地/破壊/自分/相手etc...<br>
                    そこから更に前後の文字や文脈で単語を予測することが出来ます<br>
                    例）■■■する→ドローする、■■に加える→手札に加える<br>
                  </p>

                  <p class="section">
                    <strong>予測した単語を埋めていこう</strong>
                  </p>
                  <p>
                    最初は多くの単語が隠れていて難しいと思っていても、文言を埋めると分かるようになっていきます。<br>
                    <br>
                    遊戯王で一例を挙げるとすると、<br>
                    例えば、「■■■■■■■■の■■・■■■■■枚を■■として■■する」があったとして<br>
                    「■■する」は「破壊する」「除外する」が濃厚<br>
                    「■■として破壊する」→「対象として破壊する」<br>
                    「■枚」→「1枚」～「3枚」くらい<br>
                    破壊する候補は「カード」「モンスター」「魔法」「罠」など→モンスターは文字数オーバー<br>
                    文脈から「魔法・罠カード1枚を対象として破壊する」あたりだと予想<br>
                    魔法・罠カードが存在するのはデッキ、手札、フィールド、墓地といくつかあるが、<br>
                    「破壊する」と考えると可能性が高いのは「フィールド」<br>
                    文字数が多いので「相手フィールド上の」などの装飾が入るかも...<br>
                    <br>
                    ...という風に分かるところから埋めていき推測していくことで順々に判明していきます。<br>
                    推測文字を入力すると他ユーザーにも共有されるので積極的に入力していきましょう！
                  </p>

                  <p class="section">
                    <strong>ジャンルの特徴を掴もう</strong>
                  </p>
                  <p>
                    それぞれのカードジャンルでは特定の言い回し・表記が存在しています。<br>
                    <br>
                    例として、遊戯王であれば、<br>
                    ・括弧「」で囲われた中の文字はモンスター名もしくはテーマ名<br>
                    ・効果が2つあればペンデュラム確定で、上がペンデュラム効果<br>
                    ・最初の一文が「～～～」＋「～～～」や「～～～」×２体→融合モンスター濃厚<br>
                    ・最初の一文が～～～～～～×２体→リンクやエクシーズ？<br>
                    <br>
                    デュエマであれば、<br>
                    ・クリーチャーの場合、最初に「■・■■■■」や「■・■■■■■」などが来る<br>
                      →ちなみに上のは「S・トリガー」「W・ブレイカー」など。頻出の能力は覚えておくと吉<br>
                    ・最初の文字が「■■■■■」なら「クロスギア」、「■」なら「城」など、アタリを付けると考えやすい<br>
                      →ただし、「EXライフ」など、同じ文字数で違う能力もあるので過信は禁物。<br>
                    ・「▶」という記号がある場合、いくつかの効果から1つ（や2つ）を選ぶ効果<br>
                    <br>
                    ...などなど。<br>
                    これは一例でしかないので、自分の中での攻略法を見つけてください！
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-tabs-window-item>

    <v-tabs-window-item value="policy">
      <v-container>
        <v-row>
          <v-col>
            <v-card class="mx-auto my-4" max-width="800">
              <v-card-title class="headline">プライバシーポリシー</v-card-title>
              <v-card-text>
                <div class="content">
                  <p class="section">
                    <strong>個人情報の保護について</strong>
                  </p>
                  <p>
                    「https://fillingcard.3zukie.jp」（以下、当サイト）を利用される方は、以下に記載する諸条件に同意したものとみなします。
                  </p>
                  
                  <p class="section">
                    <strong>個人情報の利用目的</strong>
                  </p>
                  <p>
                    利用者は匿名のままで、当サイトを自由に閲覧・ゲームのプレイをする事ができます。<br>
                    当サイトでは、お問い合わせやログインの際、名前やメールアドレス等の個人情報を入力いただく場合がございます。<br>
                    取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。
                  </p>

                  <!-- <p class="section">
                    <strong>広告について</strong>
                  </p>
                  <p>
                    当サイトでは、第三者配信の広告サービス（忍者AdMax）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。<br>
                    クッキーを使用することで当サイトは利用者のコンピュータを識別できるようになりますが、利用者個人を特定できるものではありません。
                  </p> -->

                  <p class="section">
                    <strong>アクセス解析ツールについて</strong>
                  </p>
                  <p>
                    当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。<br>
                    トラフィックデータは匿名で収集されており、個人を特定するものではありません。<br>
                    Cookieを無効にする方法は「<a href="https://policies.google.com/technologies/ads?hl=j" target="_blank" rel="noopener noreferrer">広告 – ポリシーと規約 – Google</a>」をご確認ください。
                  </p>
                  
                  <p class="section">
                    <strong>免責事項</strong>
                  </p>
                  <p>
                    利用者は、当サイトを閲覧し、その内容を参照した事によって何かしらの損害を被った場合でも、当サイト管理者は責任を負いません。<br>
                    また、当サイトからリンクされた、当サイト以外のウェブサイトの内容やサービスに関して、当サイトの個人情報の保護についての諸条件は適用されません。<br>
                    当サイト以外のウェブサイトの内容及び、個人情報の保護に関しても、当サイト管理者は責任を負いません。
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-tabs-window-item>

    <v-tabs-window-item value="inquiry">
    <v-container>
      <v-row>
        <v-col>
          <p class="section">
            問い合わせやバグ報告などあればTwitterか下記の問い合わせフォームにてご連絡ください。<br>
            Twitterの方が気づきやすいかもです。
          </p>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" class="my-2" style="margin-left: 10px; margin-right: 10px;">
            <v-text-field
              v-model="form.name"
              label="名前"
              :rules="[rules.required]"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="my-2" style="margin-left: 10px; margin-right: 10px;">
            <v-text-field
              v-model="form.email"
              label="メールアドレス"
              :rules="[rules.required, rules.email]"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="my-2" style="margin-left: 10px; margin-right: 10px;">
            <v-text-field
              v-model="form.title"
              label="タイトル"
              :rules="[rules.required]"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="my-2" style="margin-left: 10px; margin-right: 10px;">
            <v-textarea
              v-model="form.inquiry"
              label="問い合わせ内容"
              :rules="[rules.required, rules.maxLength]"
              required
            ></v-textarea>
          </v-col>

          <v-col cols="12" class="my-2" style="margin-left: 10px; margin-right: 10px;">
            <v-btn @click="submitForm" :disabled="!valid" color="primary">送信</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-tabs-window-item>

    <v-tabs-window-item value="omanke">
      <v-container>
        <v-row>
          <v-col>
            <v-card class="mx-auto my-4" max-width="800">
              <v-card-title class="headline">おまんけ</v-card-title>
              <v-card-text>
                <div class="content">
                  <p class="section">
                    <strong>すぺしゃるさんくす</strong>
                  </p>
                  <p>
                    <a href="https://www.mega-yadoran.jp/" target="_blank" rel="noopener noreferrer">Webエンジニア「やど」様</a><br>
                    プログラム関連の不明点を全体的に相談に乗っていただきました！<br>
                    <br>
                    <a href="https://ullu-egg.com/" target="_blank" rel="noopener noreferrer">遊戯王非公式DB管理者「ULLUNA」様</a><br>
                    カードデータの管理などで相談に乗っていただきました！<br>
                  </p>

                  <p class="section">
                    <strong>他に作ったゲームとか</strong>
                  </p>
                  <p>
                    <a href="https://3zukie.github.io/KisikilDash/" target="_blank" rel="noopener noreferrer">キスキルダッシュ</a><br>
                    遊戯王の二次創作アクションゲームです。<br>
                    <br>
                    <a href="https://3zukie.github.io/YugiohWordle/" target="_blank" rel="noopener noreferrer">遊戯王Wordle</a><br>
                    遊戯王の非公式推測ゲームです。<br>
                    <br>
                    <a href="https://www.pixiv.net/users/94312576" target="_blank" rel="noopener noreferrer">pixivリンク</a><br>
                    描いたイラストをアップロードしています。<br>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script>
import axios from 'axios';
  export default {
    data() {
      return {
        tab: 'info', // デフォルトタブ
        hash: '', // 現在のハッシュ
        valid: false,
        form: {
          name: '',
          email: '',
          title: '',
          inquiry: ''
        },
        rules: {
          required: value => !!value || '必須項目です',
          email: value => {
            const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            return pattern.test(value) || '有効なメールアドレスを入力してください';
          },
          maxLength: value => {
            return (!value || value.length <= 500) || '500文字以内で入力してください';
          }
        }
      };
    },

    mounted() {
      this.setTabFromHash();
      window.addEventListener('hashchange', this.setTabFromHash);
    },

    watch: {
      $route(to, from) {
        if (from.hash !== to.hash) {
          this.setTabFromHash();
        }
      }
    },

    methods: {
      setTabFromHash() {
        // 現在のハッシュを取得して、dataのhashに設定
        this.hash = window.location.hash.replace('#', '');
        if (['info', 'hint', 'policy', 'inquiry'].includes(this.hash)) {
          this.tab = this.hash;
        } else {
          this.tab = 'info'; // ハッシュが無効な場合のデフォルトタブ
        }
      },

      sanitizeInput(input) {
        // SQLインジェクション対策: 入力内容をエスケープする
        return input.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/'/g, "&#39;").replace(/"/g, "&quot;");
      },
      
      submitForm() {
        if (this.$refs.form.validate()) {
          const sanitizedData = {
            name: this.sanitizeInput(this.form.name),
            email: this.sanitizeInput(this.form.email),
            title: this.sanitizeInput(this.form.title),
            inquiry: this.sanitizeInput(this.form.inquiry)
          };

          // DiscordのWebhook URL
          const webhookUrl = 'https://discordapp.com/api/webhooks/1289815502125797436/V0PfGFmQpb-uYYJ6TnzsG5tgLp1bKqKwVfjEa7uHxCY7VzdO-9qZyMOy1qs3TI9fhfqn';

          // Webhookに送信するデータの形式
          const discordPayload = {
            content: `**名前:** ${sanitizedData.name}\n**メール:** ${sanitizedData.email}\n**タイトル:** ${sanitizedData.title}\n**内容:** ${sanitizedData.inquiry}`
          };

          // DiscordにPOSTリクエストを送信
          axios.post(webhookUrl, discordPayload)
            .then(() => {
              alert('お問い合わせを送信しました！');
              // フォームのリセット
              this.form.name = '';
              this.form.email = '';
              this.form.title = '';
              this.form.inquiry = '';
              this.$refs.form.reset();
              this.$refs.form.resetValidation();
            })
            .catch((error) => {
              console.error('送信に失敗しました:', error);
            });

        } else {
          console.log('バリデーションエラーが発生しました');
        }
      }
    },

    beforeUnmount() {
      window.removeEventListener('hashchange', this.setTabFromHash);
    }
  };
</script>

<style scoped>
  .content {
    overflow-y: auto;
    max-height: 600px; /* 必要に応じて調整 */
    padding: 16px;
    font-size: 14px; /* 必要に応じて調整 */
  }

  .section {
    margin-top: 20px; /* セクションタイトルの上に空白行を追加 */
  }
</style>