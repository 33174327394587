<template>
  <!-- 最初の横線 -->
  <hr />

  <div style="display: flex; flex-wrap: nowrap; align-items: stretch;">
    <div style="flex: 1; padding-right: 10px; display: flex; flex-direction: column;">
      <h3 style="margin-bottom: 20px;">チャット欄(ゲーム影響なし)</h3>

      <!-- チャット入力欄 -->
      <form @submit.prevent="postWord" style="flex: 0; margin-bottom: 10px;">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" style="padding: 0;">
            <v-text-field
              label="雑談"
              v-model="input"
              type="input"
              variant="outlined"
              maxlength="100"
              style="width: 100%; height: 56px; margin-bottom: 10px;"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" style="padding: 0;">
            <v-btn elevation="8" size="large" type="submit" style="height: 56px; margin-bottom: 10px; margin-left: 10px;">送信</v-btn>
          </v-col>
        </v-row>
      </form>

      <!-- チャット履歴 -->
      <div style="flex: 1; overflow-y: auto; border: 1px solid #ccc; padding: 10px; margin-top: 20px; max-height: 200px;">
        <div v-for="(post, i) in posts" :key="i" style="margin-bottom: 10px;">
          <div>
            {{ post.userName }} : 
            <span :style="{ color: post.userColor }">{{ post.word }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 縦線 -->
    <div style="flex: 0.5; border-left: 1px solid #ccc; padding-left: 10px; display: flex; flex-direction: column;">
      <div class="text-h6" style="margin: 20px auto;">連続正解数：{{ winStreak }}回</div>
      <div class="text-h8">参加者一覧</div>
      <v-list>
        <v-list-item v-for="user in users" :key="user.user_id" @click="confirmKick(user)">
          <v-list-item-title 
            :style="{ 
              color: user.color,
              whiteSpace: 'nowrap', /* テキストが折り返さないようにする */
              overflowX: 'auto', /* 横スクロールを可能にする */
              textOverflow: 'clip' /* 省略記号の削除 */
            }"
          >
            <!-- バッジを表示する -->
            <span v-if="user.setting_badge && user.setting_badge !== 'なし'">
              <img 
                :src="getBadgeImage(user.setting_badge)" 
                alt="バッジ"
                style="width: 2.5em; height: auto; vertical-align: middle;" 
              />
            </span>
            {{ user.user_name }}
            <span v-if="user.user_id === hostUserId">：ホスト</span>
            <span>
              <template v-if="inGame">
                <template v-if="!readyUsers.includes(user.user_id)">
                  (未準備)
                </template>
                <template v-else-if="passedUsers.includes(user.user_id)">
                  (パス)
                </template>
                <template v-else-if="nextUsers.includes(user.user_id)">
                  (準備完了)
                </template>
                <template v-else>
                  <!-- それ以外の場合は空文字を表示 -->
                </template>
              </template>
              <template v-else>
                <template v-if="readyUsers.includes(user.user_id)">
                  (準備完了)
                </template>
                <template v-else>
                  (未準備)
                </template>
              </template>
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>

  <!-- キックモーダル -->
  <v-dialog v-model="kickModal" max-width="290">
    <v-card>
      <v-card-title>
        {{ selectedUser?.user_name }}をキックしますか？
      </v-card-title>
      <v-card-actions>
        <v-btn color="primary" text @click="kickUser">はい</v-btn>
        <v-btn color="secondary" text @click="kickModal = false">いいえ</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'ChatSpace',
    props: {
      socket: { type: Object, required: true },
      winStreak: { type: Number, required: true },
      users: { type: Array, default: () => [] },
      inGame: { type: Boolean, default: false },
      passedUsers: { type: Array, default: () => [] },
      nextUsers: { type: Array, default: () => [] },
      readyUsers: { type: Array, default: () => [] },
    },
  
    data() {
      return {
        input: "", // チャット内容
        hostUserId: "", // ホストになるユーザーID
        kickModal: false, // キック確認ダイアログの表示状態
        selectedUser: null, // キック対象ユーザー
        posts: [] // チャット表示欄
      };
    },

    computed: {
      ...mapGetters(['roomId', 'userId', 'userName']), // Vuex のゲッターをマッピング
    },
  
    watch: {
      // roomDataが更新されたときに自動的にデータを更新
      users(newVal) {
        // users配列の最初のユーザーIDをホストIDとする
        this.hostUserId = (newVal && newVal.length > 0) ? newVal[0].user_id : null;
      }
    },

    mounted() {
      // サーバーからのメッセージを受け取る
      this.socket.on("updatePost", (post) => {
        // 新しいチャットメッセージを履歴に追加
        this.posts.unshift(post);
      });
    },
  
    methods: {
      postWord() {
        this.socket.emit("post", this.input);
        this.input = "";
      },

      getBadgeImage(setting_badge) {
        switch (setting_badge) {
          case '赤':
            return require('@/assets/red_badge.jpg');
          case '金':
            return require('@/assets/gold_badge.jpg');
          case '銀':
            return require('@/assets/silver_badge.jpg');
          case '銅':
            return require('@/assets/bronze_badge.jpg');
          default:
            return null; // バッジがない場合は何も表示しない
        }
      },

      // ホストユーザーIDとvuexのユーザーIDが一致する場合のみキックモーダルを表示
      confirmKick(user) {
        if (this.userId === this.hostUserId) {
          this.selectedUser = user;
          this.kickModal = true;
        }
      },

      // ユーザーをキックする
      kickUser() {
        if (this.selectedUser && this.selectedUser.socket_id) {
          this.socket.emit('kick', this.selectedUser.socket_id); // サーバーにキックイベントを送信
        } else {
          console.error('ユーザーが選択されていません');
        }
        this.kickModal = false; // モーダルを閉じる
      }
    }
  }
  
</script>