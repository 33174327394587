<template>
  <v-row justify="start" style="margin: 0px auto -20px auto; max-width: 800px;" class="d-flex align-center">
    <v-col cols="auto">
      <div class="text-h6">名前入力:</div>
    </v-col>
    <v-col cols="8">
      <v-text-field
        label="名前"
        v-model="inputName"
        type="input"
        variant="outlined"
        hide-details="auto"
        maxlength="8"
        style="margin: 20px auto;"
        :disabled="isAuthenticated"
      ></v-text-field>
    </v-col>
  </v-row>

  <v-tabs
    v-model="joinType"
    fixed-tabs
    color="deep-purple-accent-4"
    style="margin-bottom: 20px"
  >
    <v-tab value="0">新しく部屋を作る</v-tab>
    <v-tab value="1">友達の部屋に入る</v-tab>
  </v-tabs>

  <v-tabs-window v-model="joinType">
    <v-tabs-window-item value="0">
      <v-row justify="start" style="margin: -20px auto -50px auto; max-width: 800px;">
        <v-col cols="3" class="d-flex align-center">
          <div class="text-h6">カードの種類:</div>
        </v-col>
        <v-col cols="9">
          <v-radio-group v-model="cardType" inline>
            <v-radio label="遊戯王" v-model="cardType" value="yugioh" style="margin-top: 20px;"></v-radio>
            <v-radio label="デュエマ" v-model="cardType" value="duelMasters" style="margin-top: 20px;"></v-radio>
            <v-radio label="シャドバ" v-model="cardType" value="shadowverse" style="margin-top: 20px;"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <div v-if="cardType.includes('yugioh')">
        <v-row justify="start" align="center" style="margin: -30px auto; max-width: 800px;">
          <!-- 1行目 -->
          <v-col cols="2">
            <div class="text-h6">出題範囲:</div>
          </v-col>

          <v-col cols="2">
            <v-checkbox label="すべて" v-model="yugiohRange" value="all"></v-checkbox>
          </v-col>

          <v-col cols="2">
            <v-checkbox label="初代" v-model="yugiohRange" value="DM"></v-checkbox>
          </v-col>

          <v-col cols="2">
            <v-checkbox label="GX" v-model="yugiohRange" value="GX"></v-checkbox>
          </v-col>

          <v-col cols="2">
            <v-checkbox label="5ds" v-model="yugiohRange" value="5ds"></v-checkbox>
          </v-col>
        </v-row>

        <v-row justify="start" align="center" style="margin: -30px auto; max-width: 800px;">
          <!-- 2行目 -->
          <v-col cols="2">
            <!-- 空白を空けるために空のv-colを追加 -->
          </v-col>

          <v-col cols="2">
            <!-- 空白を空けるために空のv-colを追加 -->
          </v-col>

          <v-col cols="2">
            <v-checkbox label="ZEXAL" v-model="yugiohRange" value="ZEXAL"></v-checkbox>
          </v-col>

          <v-col cols="2">
            <v-checkbox label="ARC-V" v-model="yugiohRange" value="ARC-V"></v-checkbox>
          </v-col>

          <v-col cols="2">
            <v-checkbox label="VRAINS以降" v-model="yugiohRange" value="VRAINS"></v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="start" style="margin: -20px auto; max-width: 800px;">
          <v-col cols="auto">
            <div class="toggle_container">
              <div class="toggle_text">有名カードのみ出題（おすすめ）:</div>
              <div class="toggle_button">
                <input id="toggle" class="toggle_input" type="checkbox" v-model="yugiohFamousCards" />
                <label for="toggle" class="toggle_label"></label>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="cardType.includes('duelMasters')">
        <v-row justify="start" style="margin: -30px auto; max-width: 800px;" class="d-flex align-center">
          <v-col cols="2">
            <div class="text-h6">出題範囲:</div>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="すべて" v-model="duelMastersRange" value="all" style="margin-top: 20px;"></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="勝舞編" v-model="duelMastersRange" value="Shobu" style="margin-top: 20px;"></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="勝太編" v-model="duelMastersRange" value="Katta" style="margin-top: 20px;"></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="ジョー編以降" v-model="duelMastersRange" value="Joe" style="margin-top: 20px;"></v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="start" style="margin: -20px auto; max-width: 800px;">
          <v-col cols="auto">
            <div class="toggle_container">
              <div class="toggle_text">有名カードのみ出題（おすすめ）:</div>
              <div class="toggle_button">
                <input id="toggle" class="toggle_input" type="checkbox" v-model="duelMastersFamousCards" />
                <label for="toggle" class="toggle_label"></label>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="cardType.includes('shadowverse')">
        <v-row justify="start" style="margin: 20px auto -20px auto; max-width: 800px;">
          <v-col cols="auto">
            <div class="toggle_container">
              <div class="toggle_text">ゴールドレア以上のみ出題（おすすめ）:</div>
              <div class="toggle_button">
                <input id="toggle" class="toggle_input" type="checkbox" v-model="shadowverseFamousCards" />
                <label for="toggle" class="toggle_label"></label>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-row justify="start" style="margin: -20px auto; max-width: 800px;" class="d-flex align-center">
        <v-col cols="auto">
          <div class="text-h6" for="difficulty">難易度を選択:</div>
        </v-col>
        <v-col cols="6">
          <v-select
            label="難易度"
            :items="['normal', 'hard', 'hell']"
            v-model="difficulty"
            style="margin-top: 20px;"
          ></v-select>
        </v-col>
      </v-row>

      <v-btn elevation="8" size="x-large" color="green" style="margin-top: 30px;" @click="createRoom">部屋を作る</v-btn>

    </v-tabs-window-item>

    <v-tabs-window-item value="1">
      <v-row justify="start" style="margin: -20px auto; max-width: 800px;" class="d-flex align-center">
        <v-col cols="auto">
          <div class="text-h6">部屋番号:</div>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="部屋番号"
            v-model="roomId"
            variant="outlined"
            maxlength="6"
            style="margin-top: 20px;"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn elevation="8" size="x-large" color="green" style="margin-top: 60px;" @click="enterRoom">入室する</v-btn>

    </v-tabs-window-item>
  </v-tabs-window>
  <v-row class="justify-center" style="margin-top: 30px;">
    <v-btn @click="navigateTo('/InfoPage#info')" text>遊び方説明</v-btn>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'RoomEntry',
  props: {
    socket: { type: Object, required: true },
  },
  data() {
    return {
      inputName: '', // 入力した名前
      joinType: '0',
      cardType: '',
      yugiohRange: [], // 遊戯王のカード範囲
      duelMastersRange: [], // デュエマのカード範囲
      yugiohAllSelected: false, // 遊戯王の「すべて」が選択されているか
      duelMastersAllSelected: false, // デュエマの「すべて」が選択されているか
      yugiohFamousCards: true, // 遊戯王の有名カードのみで遊ぶか
      duelMastersFamousCards: true, // デュエマの有名カードのみで遊ぶか
      shadowverseFamousCards: true, // シャドバの有名カードのみで遊ぶか
      difficulty: 'normal',
      roomId: ''
    };
  },

  watch: {
    yugiohRange(newVal) {
      if (newVal.includes("all")) {
        if (!this.yugiohAllSelected) {
          this.yugiohAllSelected = true;
          this.yugiohRange = ["all", "DM", "GX", "5ds", "ZEXAL", "ARC-V", "VRAINS"];
        }
      } else {
        if (this.yugiohAllSelected) {
          this.yugiohAllSelected = false;
          // 「すべて」が外れた時に他のチェックボックスも外す
          this.yugiohRange = this.yugiohRange.filter(val => val === "all");
        }
      }
    },

    duelMastersRange(newVal) {
      if (newVal.includes("all")) {
        if (!this.duelMastersAllSelected) {
          this.duelMastersAllSelected = true;
          this.duelMastersRange = ["all", "Shobu", "Katta", "Joe"];
        }
      } else {
        if (this.duelMastersAllSelected) {
          this.duelMastersAllSelected = false;
          // 「すべて」が外れた時に他のチェックボックスも外す
          this.duelMastersRange = this.duelMastersRange.filter(val => val === "all");
        }
      }
    }
  },

  computed: {
    ...mapGetters(['userName', 'isAuthenticated', 'userBadge']), // Vuex のゲッターをマッピング
  },

  mounted() {
    // URLのクエリパラメータからroomIdを取得
    const urlParams = new URLSearchParams(window.location.search);
    const shareRoomId = urlParams.get('shareRoomId');

    if (shareRoomId) {
      this.joinType = '1'; // 部屋に参加するタブに切り替える
      this.roomId = shareRoomId; // 部屋IDをセット
    }

    this.inputName = this.userName; // 初期値をVuexのuserNameに設定
  },

  emits: ['createRoom', 'enterRoom'], // イベントリスナーを明示的に宣言

  methods: {
    // ページ遷移を行うメソッド
    navigateTo(path) {
      this.$router.push(path);
    },

    createRoom() {
      // App.vueで使いたいので一度渡す
      this.$emit("createRoom", {
        userName: this.inputName,
        cardType: this.cardType,
        yugiohRange: this.yugiohRange,
        duelMastersRange: this.duelMastersRange,
        yugiohFamousCards: this.yugiohFamousCards,
        duelMastersFamousCards: this.duelMastersFamousCards,
        shadowverseFamousCards: this.shadowverseFamousCards,
        difficulty: this.difficulty,
        isAuthenticated: this.isAuthenticated,
        userBadge: this.userBadge,
      });
    },

    enterRoom() {
      // 文字列を数字に変更
      const roomIdInt = parseInt(this.roomId, 10);
      // App.vueで使いたいので一度渡す
      this.$emit("enterRoom", this.inputName, roomIdInt, this.isAuthenticated, this.userBadge);
    },
  }
}

</script>

<style>
/* トグルボタンのスタイル */
  .toggle_text {
    margin-right: 10px;
  }

  .toggle_container {
    display: flex;
    justify-content: center; /* 横方向の中央揃え */
    align-items: center; /* 縦方向の中央揃え */
  }

  .toggle_input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .toggle_label {
    width: 35px;
    height: 20px;
    background: #fff;
    border: 3px solid #eee;
    position: relative;
    display: inline-block;
    border-radius: 40px;
    transition: 0.4s;
    box-sizing: border-box;
  }

  .toggle_label:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    left: 0.5px;
    top: -0.3px;
    z-index: 0;
    background: #eee;
    transition: 0.4s;
  }

  .toggle_input:checked + .toggle_label {
    border: 3px solid #4BD865;
  }

  .toggle_input:checked + .toggle_label:after {
    left: 14px;
    background: #4BD865;
  }

  .toggle_button {
    position: relative;
    width: 35px;
    height: 20px;
  }
</style>