<template>
  <v-col cols="auto">
    <div class="text-h6">登録</div>
  </v-col>
  <v-sheet class="mx-auto" width="300">
    <v-form @submit.prevent="Register">
      <v-text-field
        v-model="userName"
        label="名前"
        maxlength="8"
        required
      ></v-text-field>
      <v-text-field
        v-model="email"
        label="メールアドレス"
        type="email"
        required
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="パスワード"
        type="password"
        minlength="6"
        required
      ></v-text-field>
      <v-btn class="mt-2" type="submit" block>登録</v-btn>
    </v-form>

    <!-- 登録完了モーダル -->
    <v-dialog v-model="modalVisible" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">登録完了</v-card-title>
        <v-card-text>アカウントの登録が完了しました。</v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="handleModalClose">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      userName: "",
      email: "",
      password: "",
      modalVisible: false, // モーダルの表示制御用
    };
  },

  methods: {
    // Vuex
    ...mapActions(["updateUserId", "updateUserName", "updateTokens"]),
    async Register() {
      // 入力値のバリデーション
      const invalidCharacters = /[^\p{L}\p{N}\s]/gu;
      if (!this.userName || this.userName.length > 8 || invalidCharacters.test(this.userName) || this.userName.toLowerCase() === "null") {
        alert("この名前は使用できません。");
        return;
      }

      if (!this.email || !this.password) {
        alert("メールアドレスとパスワードを入力してください");
        return;
      }
      if (this.password.length < 6) {
        alert("パスワードは6文字以上で入力してください");
        return;
      }

      try {
        // Firebase Auth
        const firebaseResponse = await axios.post(
          `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyDCs2UiYWybj5T4CrR_zUWpiQTvvMowB7c`,
          {
            email: this.email,
            password: this.password,
            returnSecureToken: true,
          }
        );

        const { localId, idToken, refreshToken } = firebaseResponse.data;

        // MySQLに登録するAPI呼び出し
        await axios.post(`${process.env.VUE_APP_API_ROOT_URL}/api/users/register`, {
          user_id: localId,
          user_name: this.userName,
        });

        console.log("MySQL 登録成功");

        // Vuexに保存
        await this.updateUserId(localId);
        await this.updateUserName(this.userName);
        await this.updateTokens({ idToken, refreshToken });

        // Display registration success modal
        this.modalVisible = true;

      } catch (error) {
        // Error handling
        const errorMessage = error.response?.data?.error?.message || error.message || "エラーが発生しました";
        console.error("登録エラー:", errorMessage);
        alert(errorMessage);
      }
    },

    // モーダル閉じる処理
    async handleModalClose() {
      this.modalVisible = false;

      // 登録・ログイン成功で移動
      this.$router.push("/AccountHome");
    },
  },
};
</script>