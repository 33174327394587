<template>
  <v-container fluid>
    <v-row class="justify-center" align="center" height="100vh">
      <v-col cols="12" sm="8" md="4">
        <v-sheet class="pa-4" elevation="2">
          <div class="text-h5 text-center mb-4">ログイン</div>
          <v-form @submit.prevent="Login">
            <v-text-field
              v-model="email"
              label="メールアドレス"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="パスワード"
              type="password"
              required
            ></v-text-field>
            <v-btn class="mt-4" type="submit" block color="primary">ログイン</v-btn>
          </v-form>
          <v-divider class="my-4"></v-divider>
          <v-row class="justify-center">
            <v-btn @click="navigateTo('/AccountRegister')" text>アカウント登録はこちらから</v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  async created() {
    const isAuthenticated = await this.$store.dispatch('checkAuth');
    if (isAuthenticated) {
      this.$router.push('/AccountHome');
    }
  },
  methods: {
    ...mapActions(['updateTokens', 'updateUserId', 'updateUserName', 'updateUserBadge']),

    navigateTo(path) {
      this.$router.push(path);
    },

    async Login() {
      try {
        const response = await axios.post(
          `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDCs2UiYWybj5T4CrR_zUWpiQTvvMowB7c`,
          {
            email: this.email,
            password: this.password,
            returnSecureToken: true,
          }
        );

        const { idToken, refreshToken, localId } = response.data;

        // VuexのupdateTokensアクションを使用してidTokenとrefreshTokenを保存
        this.updateTokens({ idToken, refreshToken });
        this.updateUserId(localId);

        // MySQLからユーザー情報を取得
        const userResponse = await axios.get(`${process.env.VUE_APP_API_ROOT_URL}/api/users/${localId}`);
        const user = userResponse.data;

        // Vuexにユーザー情報を保存
        this.updateUserName(user.user_name);
        this.updateUserBadge(user.setting_badge);

        console.log('ログイン成功:', response.data);
        this.$router.push('/AccountHome');
        window.location.reload(); // ページをリロード
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.error.message
          : 'エラーが発生しました';
        alert(errorMessage);
      }
    },
  },
};
</script>