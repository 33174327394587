<template>
  <v-dialog v-model="reEnterModalFlag" max-width="600">
    <v-card>
      <v-card-title class="text-h6">再入室確認</v-card-title>
      <v-card-text>
        <p>{{ roomUsersName[0] }}さんの部屋に再入室しますか？</p>
        <p>部屋番号： {{ roomId }}</p>
        
        <!-- 区切り線 -->
        <v-divider class="my-3"></v-divider>

        <p>現在の参加者：</p>
        <v-list dense>
          <v-list-item v-for="(name, index) in roomUsersName" :key="index">
            <v-list-item>
              {{ name }}
            </v-list-item>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reEnterRoom">はい</v-btn>
        <v-btn color="secondary" @click="cancelReEnter">いいえ</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    name: 'RoomReEntryModal',
    props: {
      socket: { type: Object, required: true },
    },
    data() {
      return {
        reEnterModalFlag: false,  // 再入室用のモーダル表示フラグ
        roomUsersName: [], // 部屋にいるユーザーたち
      };
    },

    computed: {
      ...mapGetters(['roomId', 'userId', 'userName', 'isAuthenticated', 'userBadge']), // Vuex のゲッターをマッピング
    },

    created() {
      if (this.roomId) {
        this.socket.emit("getRoomInfo", this.userId, this.roomId);
      }
    },

    mounted() {
      // 再入室モーダル表示
      this.socket.on("showReEnterModal", (roomUsersName) => {
        this.roomUsersName = roomUsersName;
        this.reEnterModalFlag = true;
      });

      // 強制入室
      this.socket.on("forcedEnter", () => {
        this.reEnterRoom();
      });
    },

    methods: {
      ...mapActions(['updateRoomId']),

      // 再入室
      reEnterRoom() {
        this.reEnterModalFlag = false; // 再入室モーダル消す
        this.socket.emit("reEnter", this.userId, this.userName, this.isAuthenticated, this.userBadge, this.roomId);
      },

      // 再入室キャンセル
      cancelReEnter() {
        this.reEnterModalFlag = false; // 再入室モーダル消す
        this.updateRoomId(null); // vuexからroomId削除
      },
    }
  };
</script>