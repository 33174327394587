<template>
  <div class="text-h6" style="margin-top: 20px;">
    回答者：{{ answerUser ? answerUser : "なし" }}
  </div>
  <div v-if="resultCardEffect1.length > 0 || resultCardEffect2.length > 0" class="text-h4" style="margin: 20px auto;">
    カード名：{{ correct_name }}
  </div>
  <div v-else class="text-h4" style="margin: 20px auto;">
    次の問題へボタンを押してください
  </div>
  <v-container class="cardEffect">
    <v-row>
      <v-col cols="12">
        <!-- HTML を表示するために v-html ディレクティブを使用 -->
        <div v-html="resultCardEffect1"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- 仕切り線。第二効果が存在する場合のみ出現 -->
        <v-divider v-if="resultCardEffect2 && resultCardEffect2.length > 0" :thickness="4" class="border-opacity-50" color="info"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- HTML を表示するために v-html ディレクティブを使用 -->
        <div v-html="resultCardEffect2"></div>
      </v-col>
    </v-row>
  </v-container>
  <h4 style="margin: 20px auto;">全員が次の問題ボタンを押すとゲームが始まります</h4>
  <v-row justify="center" style="margin: 20px auto;">
    <v-col cols="auto">
      <v-btn
        elevation="8"
        size="x-large"
        type="button"
        color="#8888ff"
        @click="resultTweet"
      >
        {{ tweetButtonLabel }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn elevation="8" size="x-large" type="button" color="#8888ff" @click="recruitTweet">募集する</v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        :disabled="isNext"
        elevation="8"
        size="x-large"
        type="button"
        @click="nextQuestion"
      >
        次の問題
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'GameResult',
    props: {
      socket: { type: Object, required: true },
      isNext: { type: Boolean, required: true },
      answerUser: { type: [String, null], required: true }, // nullを許容
      currentCard: { type: Object, required: true },
      resultCardEffect1: { type: String, required: true },
      resultCardEffect2: { type: String, required: true },
    },
  
    data() {
      return {
        correct_name: "",
      };
    },

    mounted() {
      this.correct_name = this.currentCard.card_name;
    },

    computed: {
      tweetButtonLabel() {
        return this.answerUser ? "自慢する" : "ツイート";
      }
    },

    emits: ['nextQuestion', 'resultTweet', 'recruitTweet'], // イベントリスナーを明示的に宣言
  
    methods: {
      resultTweet() {
        this.$emit("resultTweet", (this.correct_name));
      },

      recruitTweet() {
        this.$emit("recruitTweet");
      },

      // isNextの状態をMainContent.vueで持ちたいから一度戻す
      nextQuestion() {
        this.$emit("nextQuestion", this.isNext);
      },
    },

    unmounted() {
      this.correct_name = "";
    }
  }
</script>