<template>
  <!-- カラーパレット選択 -->
  <h2 style="margin-top: 10px;">カラーパレット選択</h2>
  <h4 style="margin: 20px auto;">好きな色を選択して準備完了ボタンを押してね</h4>
  <div>
    <!-- 桃 -->
    <input 
      type="button" 
      :class="{'color_button': true, 'disabled': selectedColors.includes('#FF69B4') || isReady}" 
      style="background-color: #FF69B4;" 
      @click="setColor('#FF69B4')" 
      :disabled="selectedColors.includes('#FF69B4') || isReady"
    >
    <!-- 黄 -->
    <input 
      type="button" 
      :class="{'color_button': true, 'disabled': selectedColors.includes('#E8BA00') || isReady}" 
      style="background-color: #E8BA00;" 
      @click="setColor('#E8BA00')" 
      :disabled="selectedColors.includes('#E8BA00') || isReady"
    >
    <!-- 橙 -->
    <input 
      type="button" 
      :class="{'color_button': true, 'disabled': selectedColors.includes('#FF6600') || isReady}" 
      style="background-color: #FF6600;" 
      @click="setColor('#FF6600')" 
      :disabled="selectedColors.includes('#FF6600') || isReady"
    >
  </div>
  <div>
    <!-- 水 -->
    <input 
      type="button" 
      :class="{'color_button': true, 'disabled': selectedColors.includes('#38C5FF') || isReady}" 
      style="background-color: #38C5FF;" 
      @click="setColor('#38C5FF')" 
      :disabled="selectedColors.includes('#38C5FF') || isReady"
    >
    <!-- 緑 -->
    <input 
      type="button" 
      :class="{'color_button': true, 'disabled': selectedColors.includes('#008080') || isReady}" 
      style="background-color: #008080;" 
      @click="setColor('#008080')" 
      :disabled="selectedColors.includes('#008080') || isReady"
    >
    <!-- 青 -->
    <input 
      type="button" 
      :class="{'color_button': true, 'disabled': selectedColors.includes('#1235CD') || isReady}" 
      style="background-color: #1235CD;" 
      @click="setColor('#1235CD')" 
      :disabled="selectedColors.includes('#1235CD') || isReady"
    >
  </div>
  <v-row justify="center" style="margin: 20px auto;">
    <v-col cols="auto">
      <!-- Twitterシェアボタン -->
      <v-btn elevation="8" size="x-large" type="button" color="#8888ff" style="margin-top: 20px;" @click="recruitTweet">Twitterで募集する</v-btn>
    </v-col>
    <v-col cols="auto">
      <!-- 準備完了ボタン -->
      <v-btn 
        :disabled="!userColor"
        elevation="8" 
        size="x-large" 
        style="margin-top: 20px;" 
        :class="isReady ? 'red--text' : 'green--text'"
        :color="isReady ? 'red' : 'green'"
        @click="ready"
      >
        {{ isReady ? "キャンセル" : "準備完了" }}
      </v-btn>
    </v-col>
  </v-row>
  <h4 style="margin: 20px auto;">全員が準備完了するとゲームが始まります</h4>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SelectColor',
  props: {
    socket: { type: Object, required: true },
    isReady: { type: Boolean, required: true },
    userColor: { type: [String, null], default: null },
    selectedColors: { type: Array, required: true },
  },

  data() {
    return {
    };
  },

  mounted(){
  },

  computed: {
    ...mapGetters(['roomId']), // Vuex のゲッターをマッピング
  },

  emits: ['readyToggle', 'setUserColor', 'recruitTweet'], // イベントリスナーを明示的に宣言

  methods: {
    setColor(color) {
      this.$emit("setUserColor", (color));
    },

    // isReadyの状態をApp.vueで持ちたいから一度戻す
    ready() {
      if (this.userColor) {
        console.log(this.userColor);
        this.$emit("readyToggle");
      } else {
        alert('色を選択してください');
      }
    },

    recruitTweet() {
      this.$emit("recruitTweet");
    },
  }
}

</script>

<style>
  /* カラーパレットのスタイル */
  .color_button {
    width: 50px;
    height: 50px;
    margin: 7px;
  }

  .color_button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
</style>