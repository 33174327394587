<template>
  <v-container>
    <!-- ランキングタイトル -->
    <v-row>
      <v-col cols="12" class="text-center">
        <h1 class="title">週間正解数ランキング</h1>
        <p class="subtitle">（集計期間：先週月曜～日曜）</p>
      </v-col>
    </v-row>

    <v-row>
      <!-- 1位のユーザー（大きく表示） -->
      <v-col cols="8" offset="2" class="text-center">
        <v-card class="pa-5 text-h3 mb-3" color="amber lighten-3">
          <v-card-title>1位: {{ filledTopUsers[0]?.user_name }}</v-card-title>
          <v-card-subtitle>{{ filledTopUsers[0]?.weekly_correct_answers }}問正解</v-card-subtitle>
        </v-card>
      </v-col>

      <!-- 2～4位のユーザー -->
      <v-col cols="8" offset="2" class="text-center" v-for="(user, index) in filledTopUsers.slice(1, 4)" :key="user.user_name">
        <v-card class="pa-3 text-h5 mb-2" color="light-blue lighten-4">
          <v-card-title>{{ index + 2 }}位: {{ user.user_name }}</v-card-title>
          <v-card-subtitle>{{ user.weekly_correct_answers }}問正解</v-card-subtitle>
        </v-card>
      </v-col>

      <!-- 5～10位のユーザー（小さく、幅も短く表示） -->
      <v-col cols="6" class="text-center" v-for="(user, index) in filledTopUsers.slice(4, 10)" :key="user.user_name">
        <v-card class="pa-2 text-h6 mb-2" color="green lighten-4">
          <v-card-title>{{ index + 5 }}位: {{ user.user_name }}</v-card-title>
          <v-card-subtitle>{{ user.weekly_correct_answers }}問正解</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      topUsers: [],
    };
  },

  computed: {
    filledTopUsers() {
      // データが10件未満の場合は「-」で補完
      const defaultUser = { user_name: '-', weekly_correct_answers: '-' };
      return [...this.topUsers, ...Array(10 - this.topUsers.length).fill(defaultUser)];
    },
  },

  async created() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_ROOT_URL}/api/ranking/top-users`);
      this.topUsers = response.data;
    } catch (error) {
      console.error('API呼び出し失敗:', error);
    }
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px; /* カードの角を少し丸く */
}
</style>