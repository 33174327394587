      // 遊戯王黒塗りワードの配列
      export const yugiohNgWords = [
        "@イグニスター", "チュウボーンJr.", 

        "幻魔の殉教者", "かっとビング", 

        "Gゴーレム",  "V-LAN", "Ｖ－ＬＡＮ", "原始生命態", "冥府の使者", "呪眼の眷属", "つぶコーン", "テルスの羽", "戦華の龍兵", 

        "魔法使い", "兵隊アリ", "影武者狸", "水晶機朽", "電池メン", "贄の石碑", "人造天使", "ホープ剣", "引き分け", "総攻撃力", 

        "爬虫類", "幻想魔", "お互い", "一番上", "一番下", "表向き", "裏向き", "生け贄", "代わり", "霊魂鳥", "海造賊", "騎甲虫", "黒き獣", "白き獣", "双天魂", "古代の", 
        "おやつ", "雲魔物", "邪眼神", "不知火", "閃刀姫", "火の玉", "氷結界", "決闘竜", "持ち主", "皇の鍵", "武士道", "少ない", "幻獣機", "当たり", "どちら", "元通り", 
        "新しい", "いずれ", "不可能", "正しい", 

        "召喚", "通常", "生贄", "特殊", "反転", "攻撃", "守備", "SR", "ＳＲ", "EX", "ＥＸ", "LP", "ＬＰ", "BF", "ＢＦ", "TG", "ＴＧ", "GG", "ＧＧ", "GP", "ＧＰ", "Em", "Ｅｍ", 
        "融合", "表示", "装備", "手札", "墓地", "除外", "自分", "相手", "魔法", "天使", "昆虫", "永続", "速攻", "宣言", "形式", "計算", "幻煌", "氷剣", "銃士", "騎士", "相剣", 
        "発動", "効果", "場合", "半分", "戦士", "鳥獣", "悪魔", "存在", "恐竜", "海竜", "岩石", "植物", "機械", "幻竜", "表側", "裏側", "幻神", "創造", "以下", "以上", "対象", 
        "選択", "同名", "破壊", "直接", "無効", "終了", "戦闘", "勝利", "敗北", "祝台", "合計", "順番", "必要", "数値", "同じ", "使用", "素材", "儀式", "適用", "処理", "全て", 
        "氷水", "幻耀", "百檎", "溟界", "歯車", "落魂", "朧影", "家臣", "共闘", "巨竜", "黒羊", "幻影", "幻魔", "黒焔", "仔羊", "魂魄", "邪精", "綿毛", "遺物", "逃羊", "炎星", 
        "ボム", "魔草", "響鳴", "成功", "失敗", "種類", "属性", "種族", "確認", "回復", "可能", "限り", "変更", "以外", "状態", "最大", "最小", "音響", "壊獣", "低い", "元々",
        "黒羽", "警邏", "幻覚", "強欲", "時計", "燃焼", "倍々", "方界", "捕食", "魔石", "魔力", "妖仙", "鱗粉", "降臨", "公開", "宝玉", "自身", "多い", "高い", "自身", "山札", 
        "最初", "最後", "開始", "片方", "両方", "交換", "指定", "扱い", "経過", "解除", "発生", "方法", "枚数", "正規", "同一", "縦列", "相互", "上記", "下記", "条件", "無視", 
        "以降", "複数", "好き", "残り", "偶数", "奇数", "強制", "任意", "同時", "一番", "制限", "公式", "同士", "位置", "左側", "右側", "再度", "再び", "ヵ所", "カ所", "天威", 
        "勇者", "神碑", "毒蛇", "移動", "正面", "両隣", "必ず", 

        "星", "攻", "守", "？", "?", "１", "２", "３", "４", "５", "６", "７", "８", "９", "０", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "白", "羊", "極", "狐", 
        "D", "Ｄ", "闇", "光", "地", "水", "炎", "風", "神", "雷", "力", "後", "先", "場", "倍", "罠", "獣", "龍", "他", "名", "X", "S", "L", "P", "A", "Ｘ", "Ｓ", 
        "Ｌ", "Ｐ", "Ａ", "化", "楔", "上", "下", "事", "魚", "族", "属", "分", "数", "中", "N", "Ｎ", "邪", "聖", "蝕", "次", "差", "内", "前", "表", "裏", "毎", 
        "一", "二", "三", "四", "五", "六", "七", "八", "九", "際", "逆", "間", "隣", "元", "別", 
      ];

      // デュエマ黒塗りワードの配列
      export const duelMastersNgWords = [
        "OVERハイパー化", "キリフダReVo", "超天フィーバー", "アビスへの誘い", "蓄積された魔力", "Mt.富士山ックス",

        "ランチャーE", "DL-Sys", "ミノまじかる", "魔光大帝ネロ", "マジックマ瀧", 

        "特殊タイプ", "EXライフ", "虹帝双極∞", "無月の∞罪", "禁断の鼓動", "S-MAX", "無月の影罪", "無月の大罪", "でんぢゃら", "爆進ダブル", "裁きの紋章", 
        "美孔麗王国", "不死樹王国", "破壊神デス", "すべっカム", 

        "無敵虹帝", "オレ進化", "カード名", "カワイさ", "禁断解放", "禁断機動", "禁断爆発", "ゲーム台", "激流連鎖", "罠金乱舞", "遊撃師団", "天地命動", "毎ターン", 
        "破天九語", "出ること", "どこから", "ちょうど", "ここのつ", "にゃんこ", "暴拳王国", 

        "表向き", "Evo", "三人組", "幸せの", "逆さま", "すべて", "超次元", "デュ円", "裏向き", "元通り", "ひとつ", "ふたつ", "みっつ", "よっつ", 
        "いつつ", "むっつ", "ななつ", "やっつ", "持ち主", "革命軍", "侵略者", "起源神", "はじめ", "超化獣", "の花嫁", "覚醒者", "お互い", "の集い", 
        "魔導具", "無月の", "横向き", "かわり", "好きな", "白騎士", "聖霊王", "代わり", "逆転撃", "小さい", "大きい", "一番上", "一番下", "ゲーム", 
        "終わり", "始まり", "菌次郎", "ひとり", "攻撃先", "新しい", "少なく", "例えば", "龍回避", "無限大", "いずれ", "しずく", 

        "相手", "攻撃", "解除", "覚醒", "会場", "革命", "合体", "可能", "環境", "ギガ", "召喚", "禁断", "効果", "必殺", "光臨", "以上", "以下", "鼓動", "進化", 
        "最終", "究極", "極限", "能力", "幸せ", "多色", "武装", "開始", "残り", "中止", "無限", "返霊", "自分", "自身", "灼熱", "終極", "宣言", "種族", "呪文", 
        "酔い", "侵略", "S級", "仁義", "龍解", "全て", "中央", "殲滅", "地獄", "墓地", "山札", "追加", "手札", "無視", "天才", "特性", "土地", "破壊", "最後", 
        "変更", "銀河", "切札", "爆誕", "無月", "夢幻", "無色", "以外", "要塞", "輪廻", "連鎖", "転生", "自然", "順序", "順番", "同じ", "勝利", "敗北", "王国", 
        "月光", "鬼札", "場合", "死神", "紫電", "神帝", "武者", "大和", "名人", "竜音", "聖竜", "神羅", "指定", "名前", "最初", "合計", "開封", "阻止", "解放", 
        "極太", "両方", "マナ", "偶数", "奇数", "数字", "枚数", "最大", "最小", "水晶", "勝ち", "負け", "装備", "ほう", "余分", "直前", "回数", "不死", "同士", 
        "宇宙", "原始", "轟速", "天災", "勝敗", "必要", "展開", "無効", "百仙", "閻魔", "種類", "以前", "以後", "星雲", "封印", "文明", "体神", 

        "∞", "Ω", "音", "鬼", "下", "上", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "城", "真", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "元", 
        "罪", "左", "右", "後", "超", "各", "中", "前", "龍", "流", "侍", "化", "桁", "他", "門", "零", "號", "邪", "爆", "炎", "閃", "海", "鬼", "風", "何", "１", "２", 
        "３", "４", "５", "６", "７", "８", "９", "０", "虚", "絶", "終", "横", "火", "闇", "光", "水", "間", "両", "猫", "犬", "団", "?", "？", "A", "B", "C", "D", "E", "F", 
        "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "!", "！", "数", "漢", "Ａ", "Ｂ", "Ｃ", "Ｄ", "Ｅ", "Ｆ", "Ｇ", 
        "Ｈ", "Ｉ", "Ｊ", "Ｋ", "Ｌ", "Ｍ", "Ｎ", "Ｏ", "Ｐ", "Ｑ", "Ｒ", "Ｓ", "Ｔ", "Ｕ", "Ｖ", "Ｗ", "Ｘ", "Ｙ", "Ｚ", "逆", "上", "下", "次", "側", "束", "Κ", "誰", 
        "孫", "絵",
      ];

      // シャドバ黒塗りワードの配列
      export const shadowverseNgWords = [
      "眷属への贈り物", "バイヴカハの福音", "這いずるスライム", "ペガサスの結晶像", "《暴走》する戦車", "ジンジャーの言霊", "アスタロトの宣告", "レオニダスの遺志", 
      "妖精の大あくび", "聖なる王の獅子", "マナリアの見習い教師", "プリンにしてやるの", "静かなるメイド", "ソロモンの指輪", "貪り食うベヒーモス", "よろめく不死者", 
      "頑強のマンマル1号", "真夜中の吸血鬼", "ロレーナの聖水", "ロレーナの鉄拳", "聖なる盾の獅子", "聖なる鎧の獅子", "ロココのテディベア", "異次元からの侵略", 
      "カイザの薬膳料理", "カイザの激辛料理", "シャムのいたずら", "シャマのまなざし", "優しき森の美女", "怒れる森の野獣", "ミストリナの刃", "ベイリオンの号令", 
      "昏き底より出でる者", "ヴァイディの爪牙", "鳴動のギガントスカル", "闇喰らいの蝙蝠", "ムニャールの躍動", "ベルフォメットの支配", "つかの間の幸福", 
      "リモニウムの救済", "ロボミ発進指令", "ベルフォメットの弾圧", "十天衆を総べし者", "不撓不屈の騎士", "竜殺しの騎士", "エンネアの献身", "まどわしの魔狼", 
      "奮闘する格闘家", "封じられし熾天使", "目覚めし熾天使", "ディースの裁き", 

      "天使の伴奏者", "黄金の首飾り", "戦慄の海賊旗", "隠された財宝", "マンマル1号", "伊達の大見得", "森羅万象の愛", "安らぎの女神", "星見の望遠鏡", "邪悪なる指揮", 
      "オニオン軍団", "聖域の執行者", "双姫の大魔術", "アンの大英霊", "グレアの灼熱", "竜帝の近衛兵", "忌むべき教示", "深緑の弓使い", "究明の魔術師", "終幕の吸血鬼", 
      "次元の超克者", "まどろみの森", "騎士王の威光", "ナテラの大樹", "ナテラの宝樹", "妖精の目覚め", "永生の大魔女", "闇魔法の教師", "光魔法の教師", "聖獣への誓い", 
      "盟約の熾天使", "流れ行く慈悲", "飛翔する狩鳥", "希望の戦術家", "極彩の美剣士", "蒙昧なる術式", "美麗なる術式", "グレアの炎熱", "笑顔の奇術師", "全死の裁定者", 
      "守られた幸福", "白夜の吸血鬼", "神鳥の呼び笛", "エイラの祈祷", "白翼への祈り", "混沌の目覚め", "不可視の技巧", "ライルの人形", "破壊を齎す者", "殺戮を齎す者", 
      "絶望を齎す者", "深き森の異形", "美麗なる神秘", "フランの従僕", "フランの呪い", "夜天の吸血鬼", "呪剣の吸血鬼", "聖獅子の結晶", "獣姫の呼び声", "慈悲無き激流", 
      "欲望を纏う者", "異端なる冥獣", "天使のドレス", "悪魔のドレス", "ナハトの私兵", "高機動魔導車", "遺されし電撃", "グランの覚悟", "ジータの決心", "大いなる回帰", 
      "天喰らう異形", "致死の猛毒針", "自然への回帰", "俊英の双剣士", "冥河の渡し守", "ブリキの兵隊", "特級魔導列車", "乙姫お守り隊", "地獄の解放者", "うたかたの月", 
      "鉄腕の修道女", "必中の訓練生", "アンの大魔法", "無欲への恩寵", "飽くなき切望", "暗闇を招く者", "開闢の予言者", "幽闇の狩猟者", "隠されし賢者", "糸蜘蛛の悪魔",

      "堅牢なる盾", "黄金の小剣", "王城の守衛", "生命の培養", "生命の量産", "干絶の甘露", "財宝の地図", "大地の魔片", "紅蓮の魔術", "大地の魔拳", "番犬の右腕", 
      "番犬の左腕", "愛絶の飛翔", "眷属の召喚", "気高き教理", "福呼びの狐", "勇気の少年", "解放の少女", "絶命の痛撃", "真理の術式", "空絶の残光", "炎将の二槍", 
      "商人の鑑定", "麗しき美技", "万能の奇跡", "波濤の暴圧", "絶叫の拡散", "惑わぬ意志", "呪いの銅貨", "銀矢の乱射", "存在の霧散", "壮麗なる隼", "大翼の白竜", 
      "神器の使者", "神器の番人", "絶望の奔流", "奏絶の独唱", "侮蔑の炎爪", "烈絶の滅牙", "絶大の証明", "森の遊撃者", "水銀の断絶", "異界の生命", "撃針の看守", 
      "絶尽の偽証", "魔眼の蛇神", "現れる狂気", "聖なる教示", "神秘の遺物", "従順な駿馬", "忠義の剣士", "竜槍の戦士", "友魂の少女", "贖罪の司祭", "魔法図書館", 
      "鳳凰の庭園", "冒涜の墓場", "鮮血の花園", "密林の守人", "九尾の決意", "妖精の学習", "九尾の決別", "歴戦の武具", "真偽の逆転", "森の音楽隊", "信念の加速", 
      "機動二輪車", "聖なる願い", "治癒の祈り", "空虚の統制", "偽りの一閃", "真なる斬撃", "冒涜の化身", "機兵の招集", "機構の眩耀", "探偵の調査", "魔導装甲車", 
      "深緑の純心", "若き自由猫", "絶望の使者", "眠れる獅子", "眠れる輝竜", "鋭利なる剣", "剥落の暴圧", "銀氷の吐息", "覇道の金龍", "覇道の銀龍", "踏み荒らし", 
      "吹き飛ばし", "魔鎧の騎手", "骸骨の騎手", "禁絶の一撃", "禁絶の圧倒", "禁忌の復活", "蒼炎の魔石", "紅炎の魔石", "聖別の乙女", "堕落の乙女", "真紅の解放", 
      "モノの覚悟", "血族の養育", "僧侶の聖水", "欠陥の救済", "大翼の灯火", "安息の少女", "悪戯な少女", "機械生命体", "粛清の一手", "精霊の呪い", "絡みつく蔦", 
      "怒れる山神", "浄化の聖弾", "燃えネズミ", "悲哀の赤竜", "憤怒の碧竜", "黒龍の呪い", "竜殺しの槍", "巻き起こし", "処刑人の斧", "規律の聖痕", "聖女の号令", 
      "粛清の一刀", "惨禍の円環", "遮断の触手", "研磨の魔法", "辺獄の悪鬼", "薔薇の一撃", "導きの予言", "滅亡の予言", "魔女の大釜", "才能の開花", "白亜の指令", 
      "白亜の瞑想", "絶速の甲虫", "絶死の毒蜂", "絶護の魔蠍", "絶刃の蟷螂", "絶伏の怪蝶", "ドクロ親父", "漆黒の契約", "狂信の呪具", "断罪の燃焼", "純白の聖鳥", 
      "漆黒の魔鳥", "白き破壊の", "殺戮の人形", "殺戮の女神", "不鎮の女神", "憤怒の女神", "仁義の発動", "迷林の大梟", "魔導四輪車", "威武の私兵", "白銀の閃き", 
      "漆黒の剣鬼", "永遠の誓い", "冥界の闘犬", "神殿の狂鬼", "明転の牝鹿", "暗転の牝鹿", "虚脱の剛腕", "規律の道標", "光輝の聖鳥", "暗黒の魔鳥", "万象の加護", 
      "森羅の怒り", "瞬速の大狼", "不滅の群青", "輝ける剣撃", "軍師の妙策", "漆黒の曙光", "永久の聖剣", "魔術の一撃", "爆炎の咆哮", "久遠の秘薬", "刹那の劇薬", 
      "邪食の甲虫", "邪裂の蟷螂", "邪翔の怪蝶", "頂きの教会", "焼けた鉄靴", "獅子の祝福", "不滅の聖剣", "王たる屍術", "最果ての骸", "真紅の一撃", "囁く昏き者", 
      "沈黙の魔将", "決意の人形", "復讐の人形", "蹂躙の触手", "殺戮の蹂躙", "不鎮の蹂躙", "憤怒の蹂躙", "鮮烈の言霊", "爆炎の痛撃", "再生の殺戮", "再誕の不鎮", 
      "再燃の憤怒", "極光の天使", "熾天使の剣", "冥府への道", "輝竜の皇女", "気鋭の猛蹴", 

      "土の秘術", "黄金の杯", "黄金の靴", "輝く金貨", "そのまま", "操り人形", "武装強化", "天使の泉", "森林の狼", "引き直し", "幼狼奮闘", "幼き人狼", "一ツ尾狐", "魔神の剣", 
      "切り上げ", "切り捨て", "赤き猛竜", "竜山温泉", "伝説の剣", "闇夜の姫", "夜の襲来", "スピネの", "森番の弓", "緑の顕現", "黒の女王", "の副団長", "の魔術師", "の武闘家", 
      "術式結界", "破邪の光", "森の参謀", "氷獄の王", "の試験場", "混沌の闇", "博愛の雫", "真紅の絆", "隠伏天誅", "の純白竜", "の漆黒竜", "土の魔片", "竜の創造", "深淵の夢", 
      "魂の一刀", "魂の一閃", "鮮血の牙", "清浄の狐", "天馬召喚", "常闇の剣", "援護射撃", "特命捜査", "天下隠伏", "知恵の光", "蒼の放出", "踏み潰し", "毒牙の姫", "潜入捜査", 
      "星の奔流", "慈愛の雫", "蔦の魔弾", "黄昏の刃", "王の一閃", "大安売り", "新婚夫婦", "影の侵食", "竜の闘気", "影の拒絶", "加速装置", "解放奥義", "母なる君", "蜂の毒針", 
      "白の息吹", "黒の息吹", "奈落の冠", "白き舞踏", "黒き舞踏", "刃ネズミ", "絆の覇竜", "死の宣告", "電磁サイ", "魔導列車", "の煌めき", 

      "指揮官", "土の印", "すべて", "改良型", "お互い", "堕天使", "の魔弾", "の防陣", "の秘術", "不定の", "没頭の", "聖騎兵", "防御型", "攻撃型", "切り株", 
      "二刀流", "新しい", "白の章", "黒の章", "暴威の", "実験体", "突風の", "骸骨虫", "の咆哮", "の威光", "白の王", "大嵐の", "茨の森", "の人形", "少ない", 
      "素敵な", "危険な", "発明品", "兵招集", "の輝き", "の導き", "の抱擁", "の練成", "の白竜", "の黒竜", "の執事", "の詠唱", "の反逆", "白鱗の", "黒鱗の", 
      "紅の牙", "の闘争", "晴天の", "荒天の", "の戦技", "魔将軍", "断罪者", "活竜剣", "殺竜剣", "竜の翼", "氷結の", "の祈り", "光の剣", "闇の剣", "渇望の", 
      "の激情", "炎撃槍", "氷撃槍", "の憤怒", "収穫祭", "凶暴化", "の本気", "騎竜兵", "の兵士", 

      "守護", "相手", "攻撃", "突進", "消費", "制限", "進化", "疾走", "潜伏", "必殺", "覚醒", "復讐", "交戦", "召喚", "結晶", "渇望", "狂乱", "EP", "ＥＰ", "PP", "ＰＰ", 
      "操縦", "変身", "兵士", "財宝", "機械", "自然", "奥義", "学園", "自分", "以降", "終了", "回復", "手札", "以上", "以下", "破壊", "能力", "消滅", "同名", "重複", "公開", 
      "ごと", "選択", "同じ", "種類", "直前", "多い", "高い", "残り", "不能", "墓場", "体力", "開始", "複数", "低い", "済み", "状態", "初手", "ミミ", "ココ", 
      "前者", "後者", "最も", "古い", "無視", "可能", "端数", "共鳴", "直接", "葬送", "宴楽", "武装", "八獄", "終了", "枚数", "上限", "回数", "半分", "名前", "新約", "融合", 
      "連携", "式神", "形代", "暴鬼", "天后", "貴人", "上記", "合計", "対象", "痛苦", "緊急", "毒蛇", "最小", "最大", "の卵", "詠唱", "長久", "白蛇", "運命", "現在", "奇数", 
      "偶数", "大狼", "炎帝", "光輝", "空き", "必要", "海賊", "場合", "邪竜", "聖竜", "両方", "下記", "以外", "未満", 

      "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "X", "Y", "１", "２", "３", "４", "５", "６", "７", "８", "９", "０", "Ｘ", "Ｙ", "前", "後", "数", "中", "次", 
      "倍", "順", "元", "力", "場", "次", "他", "値", "閃", "V", "Ｖ", "Z", "Ｚ", "逆", "上", "下", 
      ];

      // ノーマルモード用の黒塗りワードの配列(全カード共通)
      export const normalNgWords = [
        "アバレチェーン", "イラストレーター", "インフィニティ", "ウェーブストライカー", "エクストライフ", "ツインフィニティ","OVERハイパー化", "オシオキムーン", "ブースターパック", 
        "キリフダッシュ", "キリフダReVo", "クライマックス", "超天フィーバー","マッハファイター", "ムゲンクライム", "レインボーナス", "アースイーター", "ドリームメイト", 
        "ヒューマノイド", "アダムユニット","グラディエーター", "グレートメカオー", "コロコロイヤル", "スノーフェアリー", "ビーストフォーク", "ビートジョッキー","ディスペクター", 
        "デューンゲッコー", "デンヂャラスアビス", "トライストーン", "パラサイトワーム", "マッシュルーム","ビッグマッスル", "フェザーノイド", "ブレインジャッカー", "ライトブリンガー", 
        "ソルトルーパー", "アビスへの誘い", "キカイヒーロー", "リヴァイアサン", "パンドラボックス", "ワンダフォース", "ファイブスター", "蓄積された魔力", "Mt.富士山ックス", 
        "コントローラー", "ファンファーレ", "アーティファクト", "ネクロマンサー", "スペルブースト", "カウントダウン", "アクセラレート", "ユニオンバースト", 

        "キズナコンプ", "コレクション", "チャージャー", "ジェネレート", "ドラグハート", "ツインパクト", "フォートレス", "フシギバース", "マジボンバー", "ランチャーE", "DL-Sys", 
        "ミノまじかる", "メテオバーン", "コントロール", "ルナティック", "イニシエート", "ドラゴノイド", "アーマードン", "アーマロイド", "アウトレイジ", "ジャイアント", 
        "イニシャルズ", "イブユニット", "オラクリオン", "ガーディアン", "グランセクト", "スーパーカー", "クリエイター", "ポセイディア", "シャイニング", "ジャスティス", 
        "ジュラシック", "ジョーカース", "ジョーカーズ", "スターノイド", "スクラッパー", "スターライト", "スプラッシュ", "スペシャルズ", "ダークロード", "ダイナマイト", 
        "デスパペット", "デリートロン", "バーサーカー", "フェニックス", "マーフォーク", "サンガイザー", "レクスターズ", "クルセイダー", "ハンティング", "ガンヴィート", 
        "ボルシャック", "ボルメテウス", "魔光大帝ネロ", "ビビッドロー", "ペンデュラム", "ヴァンパイア", "ニュートラル", "ラストワード", "ネクロマンス", "リアニメイト", 
        "アミュレット", "ミスティック", "エンシェント", "レディアント", 

        "アドバンス", "エクシーズ", "フィールド", "サイバース", "サイキック", "アンデッド", "チューナー", "S-MAX", "無月の∞罪", "虹帝双極∞", "フォートE", "EXライフ", 
        "特殊タイプ", "禁断の鼓動", "クロスギア", "サイキック", "サイクロン", "スレイヤー", "サイレント", "ササゲール", "アンタップ", "ブレイカー", "エグザイル", "スタンバイ", 
        "サバイバー", "シャッフル", "ジョラゴン", "エスケープ", "無月の影罪", "無月の大罪", "プレイヤー", "シンパシー", "スマッシュ", "スリリング", "レインボー", "でんぢゃら", 
        "ドキンダム", "ドギラゴン", "ブロッカー", "ストライク", "爆進ダブル", "バズレンダ", "ヒーローズ", "ビクトリー", "フェアリー", "フレーバー", "マーシャル", "トルネード", 
        "メラビート", "ブラスター", "ラビリンス", "メクレイド", "メタモーフ", "リサイクル", "リサイタル", "ワンダブル", "アポロニア", "ガーゴイル", "ウォーカー", "ファイアー", 
        "ヘドリアン", "ワイバーン", "シスターズ", "チャンネル", "アンノイズ", "アンノウン", "エンジェル", "ガイアール", "カレーパン", "クリスタル", "フィッシュ", "クラスター", 
        "サバキスト", "裁きの紋章", "インセクト", "ファンキー", "スプリガン", "スペシャル", "スリヴァー", "ゼノパーツ", "ゼロリスト", "チルドレン", "ディスタス", "タマシード", 
        "トゥルース", "ドラグナー", "トリニティ", "ドルスザク", "ナレーター", "ピアニスト", "美孔麗王国", "不死樹王国", "プレインズ", "ボルケーノ", "マーメイド", "ムートピア", 
        "ナッシング", "ジャンケン", "セラフィム", "アーマード", "エイリアン", "エメラルド", "ナイトメア", "スピリット", "ドラゴンズ", "トリックス", "ミステリー", "ムーゲッツ", 
        "エスケープ", "エターナル", "エレメント", "ガードマン", "ファイナル", "エンペラー", "ファントム", "カンクロウ", "パーロック", "リュウセイ", "破壊神デス", "ボルベルグ", 
        "バラギアラ", "グリフィス", "モモキング", "ライオネル", "エンハンス", "フォロワー", "ビショップ", "レヴィオン", "アナライズ", "バラエティ",

        "シンクロ", "ポイント", "魔法使い", "ドラゴン", "リバース", "リリース", "ダメージ", "デュエル", "スキップ", "フェイズ", "ステップ", "アクセル", "アタック", "チャンス", 
        "アッパー", "ウエポン", "ウルトラ", "セイバー", "エヴィル", "無敵虹帝", "オレ進化", "ジャッジ", "ガチンコ", "カワイさ", "人造天使", "ホープ剣", "水晶機朽", "電池メン", 
        "キッカー", "禁断解放", "禁断機動", "禁断爆発", "激流連鎖", "スーパー", "ホーガン", "ギャラク", "スイッチ", "トリガー", "フォース", "シリーズ", "ダイバー", "ジャスト", 
        "チェンジ", "ステルス", "ジャシン", "スペース", "チャージ", "バースト", "ダイナモ", "フリーズ", "ブレイク", "破天九語", "サンダー", "ヴォルグ", "ドレイン", "贄の石碑", 
        "トリプル", "マジック", "チョイス", "パラレル", "ジョット", "天地命動", "ジョニー", "パンドラ", "アイコン", "フレンド", "フュリー", "プラチナ", "ボーナス", "ブロック", 
        "ホーリー", "ブレイン", "ホールド", "マスター", "ここのつ", "サムライ", "メタリカ", "アイドル", "キマイラ", "ベイビー", "トラップ", "ロイヤル", "ヴェール", "エルダー", 
        "コマンド", "オラクル", "オリジン", "ハザード", "グランド", "グリーン", "ゴースト", "コロニー", "ビートル", "サイバー", "ウイルス", "ハッカー", "ハンター", "シャイン", 
        "ウイング", "クイーン", "ウェイブ", "ボンバー", "フォーク", "ディープ", "マリーン", "ティラノ", "ドレイク", "デーモン", "デモニオ", "にゃんこ", "ノワール", "ハムカツ", 
        "バルーン", "ファイブ", "フィオナ", "ブラック", "ブレイブ", "フレイム", "暴拳王国", "ホワイト", "マジカル", "イーター", "リキッド", "ピープル", "レガシー", "トーテム",
        "ドライブ", "ハイパー", "シールド", "ネクスト", "プリズム", "ワールド", "罠金乱舞", "ソニック", "遊撃師団", "ゴブリン", "セイント", "ペガサス", "ビースト", "アンサー", 
        "リビング", "ルナーズ", "ベジーズ", "ワイルド", "ワンダー", "トリック", "ヘブンズ", "ロマノフ", "スパーク", "オーバー", "ラッシュ", "ニンジャ", "リベンジ", "ギャング", 
        "サンクス", "ビークル", "ディネロ", "ヒーロー", "アイニー", "ネメシス" ,"ウィッチ", "土の秘術", "スタック", "マナリア", "リーダー", "ランダム", "引き直し", "ブリッツ",
        "スピネの", "トレース", "プライム", "スピード", "エナジー", 

        "リンク", "デッキ", "カード", "ターン", "コスト", "アップ", "ダウン", "ドロー", "メイン", "エンド", "爬虫類", "幻想魔", "ライフ", "セット", "レベル", "MAX", "Evo", 
        "バトル", "生け贄", "ゲーム", "ゾーン", "プラス", "ソウル", "ドロン", "エイジ", "エンド", "表向き", "オレガ", "オーラ", "ガード", "エッグ", "ガイア", "ガオー", "キズナ", 
        "キング", "クルー", "クロス", "シフト", "スキル", "三人組", "幸せの", "プラス", "シビル", "逆さま", "ビッグ", "ポップ", "シンカ", "ライズ", "バック", "すべて", "リキシ", 
        "タップ", "ダブル", "超次元", "プリン", "ロード", "デュ円", "ガチャ", "トライ", "ナイト", "バイオ", "モード", "パック", "裏向き", "ハンド", "フリー", "デッド", "ラスト", 
        "ループ", "ロスト", "タイム", "アクミ", "ポリス", "イメン", "ダママ", "白騎士", "パラス", "革命軍", "デビル", "ゲリラ", "ノヴァ", "ムーン", "シノビ", "オーブ", "侵略者", 
        "起源神", "チーム", "無月の", "超化獣", "テック", "マスク", "ギルド", "コード", "ゾンビ", "の花嫁", "ドレミ", "ナーガ", "ニート", "ニトロ", "ニュー", "覚醒者", "ミルク", 
        "ガール", "ボーイ", "ステラ", "メタル", "メルト", "サバキ", "ゲーム", "プレイ", "ゾーン", "ゴッド", "ロッサ", "スリー", "アビス", "ターボ", "ゼニス", "ダーク", "横向き", 
        "ヘッド", "チェス", "アーク", "アース", "アーツ", "コスモ", "バード", "セレス", "ツリー", "の集い", "テスタ", "ブルー", "タッチ", "ホーン", "ソング", "マシン", "魔導具", 
        "マフィ", "ビビッ", "エルフ", "指揮官", "土の印", "タイプ", "お互い", "最大値", "エッジ", "スペル", "ランク", 

        "通常", "生贄", "特殊", "反転", "融合", "表示", "装備", "手札", "墓地", "除外", "S級", "EX", "ＥＸ", "EP", "ＥＰ", "PP", "ＰＰ", "LP", "ＬＰ", "半分",  "表側", "裏側", 
        "対象", "選択", "同名", "破壊", "直接", "無効", "終了", "自分", "相手", "魔法", "永続", "速攻", "戦闘", "数値", "使用", "素材", "儀式", "形式", "属性", "光臨", "鼓動", 
        "最終", "究極", "極限", "幸せ", "多色", "武装", "開始", "中止", "返霊", "自身", "灼熱", "終極", "宣言", "種族", "呪文", "酔い", "侵略", "仁義", "龍解", "全て", "中央", 
        "殲滅", "地獄", "山札", "追加", "無視", "天才", "特性", "土地", "ノー", "封印", "文明", "変更", "銀河", "切札", "シー", "爆誕", "無月", "無限", "夢幻", "無色", "メガ", 
        "竜音", "聖竜", "神羅", "指定", "名前", "名人", "要塞", "輪廻", "連鎖", "転生", "自然", "ゼロ", "ビー", "デル", "大和", "ガン", "ソル", "サン", "メカ", "カス", "王国", 
        "月光", "鬼札", "ゲル", "死神", "紫電", "神帝", "武者", "守護", "突進", "消費", "制限", "進化", "疾走", "潜伏", "必殺", "覚醒", "復讐", "交戦", "葬送", "共鳴", "直接", 
        "召喚", "結晶", "渇望", "狂乱", "連携", "奥義", "操縦", "変身", "兵士", "財宝", "宴楽", "八獄", "学園", "以降", "破壊", "消滅", "重複", "直前", "不能", "墓場", "上限", 
        "状態", "初手", "マナ", "宇宙", "原始", "轟速", "不死", "天災", "百仙", "閻魔", "同士", 

        "罠", "X", "S", "L", "P", "A", "Y", "W", "T", "Q", "Ｘ", "Ｓ", "Ｌ", "Ｐ", "Ａ", "Ｙ", "Ｗ", "Ｔ", "Ｑ", 
      ];

      // ヘルモード用の黒塗りワードの配列(全カード共通)
      export const hellNgWords = [
        "し続けなければ", "捧げなければ", "そうでなければ", "聖なる王の獅子", "静かなるメイド", "プリンにしてやるの", "そうしなければ", "書かれていない", "支払えなければ", 
        "聖なる盾の獅子", "聖なる鎧の獅子", "《暴走》する戦車", "奮闘する格闘家", "シャムのいたずら", 

        "働いていない", "積まれている", "されなかった", "させなくても", "蒙昧なる術式", "美麗なる術式", "美麗なる神秘", "邪悪なる指揮", "異端なる冥獣", "大いなる回帰", 
        "飛翔する狩鳥", "積まれていた", "流れ行く慈悲", "持っていれば", "取り除かれる", "唱えられない", "できなければ", "送らなければ", "選ばなければ", "払わなければ", 
        "出さなければ", "置かなければ", "アンの大英霊", "アンの大魔法", 
        
        "なくなった", "取り除いて", "支払わない", "働いていた", "捨てられた", "入れ替える", "捨てさせる", "入れ替えて", "やり直して", "くり返して", "割りふって", "かかわらず", 
        "決められた", "持っている", "持っていた", "していれば", "残っている", "そうしたら", "唱えられた", "いなければ", "堅牢なる盾", "壮麗なる隼", "聖なる教示", "聖なる願い", 
        "真なる斬撃", "鋭利なる剣", "移し替えて", "移し替えた", "加えられた", "かけていた", "少なければ", "捨てられる", "しなければ", "少なくなる", 

        "選べない", "なくなる", "取り除く", "取り除き", "記される", "記された", "受けない", "離れない", "めくって", "めくった", "やり直す", "やり直し", "行えない", "している", 
        "乗らない", "ならない", "されない", "できない", "支払わず", "支払って", "出せない", "送られる", "送られた", "失われる", "働かせる", "持たない", "くり返す", "与え合う", 
        "していた", "多くなる", "合わせる", "合わせて", "入れ替え", "払えない", "使えない", "使わない", "言わない", "唱えない", "働かない", "含まない", "ではない", "ではなく", 
        "なかった", "置かれた", "置かれて", "扱わない", "なかった", "加わった", "であった", "遅らせる", "隠された", "当たった", "そうした", "なければ", "置かれる", "とどまる", 
        "戻された", "始まった", "支払った", "母なる君", "繰り返す", "とどまる", "切り捨て", "送られて", "選ばれず", "選ばれた", "引かせて", "多ければ", "終わった", "起こった", 
        "選ばせる", "勝っても", "終わった", "書かれて", "戻される", "含まれる", "まとめて", "置けない", "勝てない", "除外する", "切り上げ", "切り捨て", 

        "置いた", "唱える", "唱えた", "付ける", "付けた", "選んだ", "選んで", "加える", "加えた", "加えて", "しない", "負ける", "出した", "捨てる", "捨てた", "捨てて", "重ねる", 
        "重ねて", "重ねた", "超えた", "与える", "与えた", "超える", "取り除", "引いた", "記され", "置いて", "受ける", "受けた", "離れる", "離れた", "振った", "めくる", "見せる", 
        "見せた", "戻して", "戻した", "払って", "含んだ", "続ける", "続けて", "負けた", "勝った", "出して", "行った", "行かず", "行った", "乗った", "された", "できる", "できず", 
        "なった", "される", "ついた", "唱えて", "支払う", "支払い", "失った", "送った", "送って", "めくり", "含めず", "進める", "させる", "持たず", "当てる", "捧げる", "捧げて", 
        "行わず", "捧げた", "払わず", "戻った", "戻って", "上げる", "上がる", "下げる", "下がる", "言った", "数える", "数えた", "数えて", "もよい", "も良い", "乗って", "引いて", 
        "与えて", "受けて", "離れて", "振って", "見せて", "含んで", "行って", "扱って", "なって", "ついて", "失って", "除いて", "使って", "使った", "付いて", "付いた", "加わる", 
        "含めて", "させた", "含める", "決まる", "いない", "異なる", "払った", "ならず", "揃った", "戻らず", "混ぜた", "混ぜて", "だった", "送らず", "行えず", "超えて", "いれば", 
        "あれば", "見ない", "揃って", "少なく", "始まる", "裏返し", "裏返す", "違った", "当てた", "払えば", "移った", "されず", "少ない", "持った", "移して", "移した", "送られ", 
        "載って", "されて", "いたら", "入れる", "乗せる", "対して", "なくす", "選ばせ", "留まる", "決める", "終わる", "起こる", "あった", "付けて", "みなす", "描いた", "付けて", 
        "離れず", "行われ", "除外し", "戻さず", 

        "置く", "引く", "出る", "出た", "出し", "選ぶ", "選び", "勝つ", "見る", "見た", "見て", "加え", "する", "した", "いる", "いた", "いて", "戻す", "戻し", "捨て", "重ね", 
        "超え", "与え", "得る", "得た", "受け", "離れ", "移る", "振る", "見せ", "払う", "含む", "出す", "行う", "行く", "扱う", "扱い", "乗る", "なる", "され", "つく", "置い", 
        "引き", "送る", "送り", "失う", "失い", "持つ", "除く", "働く", "ある", "限る", "戻る", "ない", "なく", "無い", "無く", "使う", "言う", "出て", "して", "得て", "付く", 
        "思う", "限り", "振り", "払い", "及ぶ", "置き", "せず", "よい", "多く", "捧げ", "違う", "あり", "移し", "なり", "開き", "行い", "移す", "下げ", "上げ", "なり", "続く", 
        "残る", "多い", "数え", "回す", "残し", "唱え", "外す", "外し", "払わ", "おく", 

        "得", 
      ];