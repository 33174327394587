import { createStore } from 'vuex';
import { jwtDecode } from 'jwt-decode'; // 修正点
import axios from 'axios';

const store = createStore({
  state() {
    return {
      roomId: localStorage.getItem('roomId') === 'null' ? null : localStorage.getItem('roomId'),
      userId: localStorage.getItem('userId'),
      userName: localStorage.getItem('userName'),
      userBadge: localStorage.getItem('userBadge'),
      idToken: localStorage.getItem('idToken') === 'null' ? null : localStorage.getItem('idToken'),
      refreshToken: localStorage.getItem('refreshToken') === 'null' ? null : localStorage.getItem('refreshToken'),
    };
  },
  mutations: {
    setRoomId(state, value) {
      state.roomId = value;
      localStorage.setItem('roomId', value);
    },
    setUserId(state, value) {
      state.userId = value;
      localStorage.setItem('userId', value);
    },
    setUserName(state, value) {
      state.userName = value;
      localStorage.setItem('userName', value);
    },
    setUserBadge(state, value) {
      state.userBadge = value;
      localStorage.setItem('userBadge', value);
    },
    setTokens(state, { idToken, refreshToken }) {
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('refreshToken', refreshToken);
    },
    clearTokens(state) {
      state.idToken = null;
      state.refreshToken = null;
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
    },
  },
  actions: {
    async checkAuth({ state, commit }) {
      const { idToken, refreshToken } = state;

      if (!idToken) {
        return false; // トークンがない場合は認証されていない
      }

      try {
        const decodedToken = jwtDecode(idToken); // 修正点
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp > currentTime) {
          return true; // トークンがまだ有効な場合
        }

        // トークンが期限切れの場合、リフレッシュトークンを使って新しいトークンを取得
        const response = await axios.post(
          `https://securetoken.googleapis.com/v1/token?key=AIzaSyDCs2UiYWybj5T4CrR_zUWpiQTvvMowB7c`,
          {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
          }
        );

        const { id_token: newIdToken } = response.data;

        // 新しいトークンをストアに保存
        commit('setTokens', { idToken: newIdToken, refreshToken });

        return true; // 新しいトークンで認証されている
      } catch (error) {
        console.error('トークンの検証に失敗しました', error);
        commit('clearTokens');
        return false;
      }
    },
    updateRoomId({ commit }, value) {
      commit('setRoomId', value);
    },
    updateUserId({ commit }, value) {
      commit('setUserId', value);
    },
    updateUserName({ commit }, value) {
      commit('setUserName', value);
    },
    updateUserBadge({ commit }, value) {
      commit('setUserBadge', value);
    },
    updateTokens({ commit }, { idToken, refreshToken }) {
      commit('setTokens', { idToken, refreshToken });
    },
  },
  getters: {
    roomId: (state) => state.roomId,
    userId: (state) => state.userId,
    userName: (state) => state.userName,
    userBadge: (state) => state.userBadge,
    idToken: (state) => state.idToken,
    refreshToken: (state) => state.refreshToken,
    isAuthenticated(state) {
      return !!state.idToken;
    },
  },
});

export default store;